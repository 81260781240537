import { Button, FormControl, FormLabel, Input, InputGroup, InputRightElement, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Stack, Text, useToast } from '@chakra-ui/react'
import React, { useContext, useState } from 'react'
import { auth, db, storage } from '../../Config/firebase'
import { doc, setDoc, updateDoc, } from 'firebase/firestore'
import { EmailAuthProvider, reauthenticateWithCredential, updatePassword } from 'firebase/auth'
import { getDownloadURL, uploadBytesResumable, ref, deleteObject } from 'firebase/storage'
import { useNavigate } from 'react-router'
import { deleteFileFirebase } from '../../Api/firebaseApi'
import { GlobalContext } from '../../Hooks/Contexts/GlobalContext'

const Modals = (props) => {
     const user = auth.currentUser;
     const toast = useToast();
     const { handleToast } = useContext(GlobalContext);
     const navigate = useNavigate()
     const { isOpen, onClose, data, setLoading, getData } = props
     const [input, setInput] = useState(null)
     const [show, setShow] = useState({ password: false, newPassword: false })
     const handleClick = (type) => {
          if (type === 'old') {
               setShow({ ...show, password: !show.password })
          } else {
               setShow({ ...show, newPassword: !show.newPassword })
          }
     }

     const handleSave = async () => {
          if (data?.type === 'name') {
               const dataRef = doc(db, "users", user.uid);
               await updateDoc(dataRef, {
                    name: input.name
               });
               handleToast('Success', 'Full name has been edited!', 'success')
               getData()

          } else if (data?.type === 'password') {
               if (user) {
                    try {
                         const credential = EmailAuthProvider.credential(
                              user.email,
                              input?.password
                         );

                         await reauthenticateWithCredential(user, credential);
                         await updatePassword(user, input?.newPassword).then(() => {
                              handleToast('Success', 'Password has been edited!', 'success')


                         }).catch((error) => {
                              console.log(error)
                         });
                         getData()

                    } catch (error) {
                         console.log(error)
                    }
               }
          } else if (data?.type === 'photo') {
               const path = `users/${user.uid}/profile/${input.image.name}`;
               const storageRef = ref(storage, path);
               const uploadTask = uploadBytesResumable(storageRef, input.image);
               uploadTask.on(
                    "state_changed",
                    (snapshot) => {
                         const progress =
                              (snapshot.bytesTransferred /
                                   snapshot.totalBytes) *
                              100;
                         console.log("Upload is " + progress + "% done");
                         if (progress !== 100) setLoading(progress);
                         else {
                              setLoading(false);
                         }
                    },
                    (error) => {
                         console.log(error.message);
                    },
                    () => {
                         getDownloadURL(uploadTask.snapshot.ref)
                              .then((downloadURL) => {
                                   console.log(
                                        "File available at",
                                        downloadURL
                                   );

                                   setInput({
                                        ...input,
                                        image: downloadURL.replace(/(\.[^.\/\\]+)$/i, '_800x800$1'),
                                   });
                                   return downloadURL;
                              })
                              .then((downloadURL) => {
                                   console.log(downloadURL.replace(/(\.[^.\/\\]+)$/i, '_800x800$1'))
                                   setInput({
                                        ...input,
                                        image: downloadURL.replace(/(\.[^.\/\\]+)$/i, '_800x800$1'),
                                        updatedAt: new Date(),
                                   });
                                   input.image = downloadURL.replace(/(\.[^.\/\\]+)$/i, '_800x800$1');
                                   console.log(input, 'ninput')
                                   // update data
                                   const ref = doc(
                                        db,
                                        "users",
                                        user.uid
                                   );
                                   setDoc(ref, input, { merge: true }).then(() => {
                                        getData()
                                        handleToast("Profile Saved", "Your profile has been saved!", 'success')

                                   }).catch((error) => {
                                        console.log(error)
                                   });                                   // setLoading(true);

                              }).finally(() => {

                              })
                    }
               );

          } else if (data?.type === 'confirm-delete') {
               const str = data?.image
               const str1 = str.split('?')
               let str2 = str1[0].split('%2F')

               if (str2[3].includes('%20')) {
                    const newStr = decodeURIComponent(str2[3]); // Mengganti %20 menjadi spasi
                    str2[3] = newStr
               }
               if (user?.uid) {
                    const refs = doc(
                         db,
                         "users",
                         user.uid
                    );

                    // Delete the file
                    try {
                         await deleteFileFirebase(str2[3], `users/${user.uid}/profile`).then(() => {
                              setLoading(true)
                              setDoc(refs, { image: null }, { merge: true }).then(() => {
                                   handleToast("Profile Deleted!", "Your profile has been deleted!", 'success')
                                   getData()
                              }).catch((error) => {
                                   console.log(error)
                              }).finally(() => {
                                   setLoading(false)
                                   getData()

                              })
                         }).catch((error) => {
                              console.log(error)
                         });
                    } catch (error) {
                         console.log(error)
                    }


               }
          }
          onClose()
     }

     return (
          <Modal isOpen={isOpen} onClose={onClose}>
               <ModalOverlay />
               <ModalContent>
                    <ModalHeader textTransform='capitalize'>
                         {data?.type !== undefined ? (data?.type?.includes('alert') ? 'Alert' : data?.type === 'confirm-delete' ? 'Confirm Delete' : `Change ${data?.type}`) : <></>}
                    </ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                         {data?.type === 'name' ? <FormControl>
                              <FormLabel>Full Name</FormLabel>
                              <Input type='text' placeholder={`Input your name`} onChange={(e) => setInput({ ...data, name: e.target.value })} />
                         </FormControl> : data?.type === 'email' ? <FormControl>
                              <FormLabel>Email</FormLabel>
                              <Input type='text' placeholder={`Input your email`} onChange={(e) => setInput({ ...data, email: e.target.value })} />
                         </FormControl> : data?.type === 'password' ? <Stack>
                              <FormControl>
                                   <FormLabel>Password</FormLabel>
                                   <InputGroup size='md'>
                                        <Input
                                             pr='4.5rem'
                                             type={show.password ? 'text' : 'password'}
                                             placeholder='Enter password'
                                             onChange={(e) => setInput({ ...input, password: e.target.value })}
                                        />
                                        <InputRightElement width='4.5rem'>
                                             <Button h='1.75rem' size='sm' onClick={() => handleClick('old')}>
                                                  {show.password ? 'Hide' : 'Show'}
                                             </Button>
                                        </InputRightElement>
                                   </InputGroup>
                              </FormControl>
                              <FormControl>
                                   <FormLabel>New Password</FormLabel>
                                   <InputGroup size='md'>
                                        <Input
                                             pr='4.5rem'
                                             type={show.newPassword ? 'text' : 'password'}
                                             placeholder='Enter password'
                                             onChange={(e) => setInput({ ...input, newPassword: e.target.value })}
                                        />
                                        <InputRightElement width='4.5rem'>
                                             <Button h='1.75rem' size='sm' onClick={() => handleClick('new')}>
                                                  {show.newPassword ? 'Hide' : 'Show'}
                                             </Button>
                                        </InputRightElement>
                                   </InputGroup>
                              </FormControl>
                         </Stack> : data?.type === 'photo' ? <FormControl>
                              <FormLabel>Profile Picture</FormLabel>
                              <Input type='file' variant='ghost' onChange={(e) => setInput({ ...data, image: e.target.files[0] })} />
                         </FormControl> : data?.type === 'confirm-delete' ? <Text>Are you sure want to delete this picture?</Text> :
                              data?.type?.includes('alert') ? <Text>You should login first !</Text> :
                                   <></>}

                    </ModalBody>

                    <ModalFooter>
                         {data?.type?.includes('alert') ? <Button colorScheme='green' onClick={() => navigate('/authentication/login')}>Login</Button> : data?.type !== 'confirm-delete' ? <>  <Button colorScheme='red' mr={3} onClick={onClose}>
                              Close
                         </Button>
                              <Button colorScheme='green' onClick={() => handleSave()}>Save</Button></> : <Button colorScheme='red' onClick={() => handleSave()}>Delete</Button>}

                    </ModalFooter>
               </ModalContent>
          </Modal>
     )
}

export default Modals