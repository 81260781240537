import { Outlet, useLocation } from 'react-router-dom';
import { motion } from "framer-motion";

export const RoutesAnimation = () => {
	const { pathname } = useLocation();
	const pageVariants = {
		initial: {
			opacity: 0.3
		},
		in: {
			opacity: 1
		},
		out: {
			opacity: 0.8
		}
	};

	const pageTransition = {
		type: 'tween',
		ease: 'easeInOut',
		duration: 0.8
	};
	return (
		<motion.div
			key={pathname}
			initial="initial"
			animate="in"
			variants={pageVariants}
			transition={pageTransition}
		>
			<Outlet />
		</motion.div>
	);
};