// import { createUserWithEmailAndPassword } from 'firebase/auth';
import React, { useState } from 'react'
// import { auth } from '../../Config/firebase';
import {
	Flex,
	Box,
	FormControl,
	FormLabel,
	Input,
	InputGroup,
	HStack,
	InputRightElement,
	Stack,
	Button,
	Heading,
	Text,
	useColorModeValue,
} from '@chakra-ui/react';
import { FiEye, FiEyeOff } from 'react-icons/fi';
import { useContext } from 'react';
import { GlobalContext } from '../../Hooks/Contexts/GlobalContext';
import { Link, useNavigate } from 'react-router-dom';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { auth, db } from '../../Config/firebase';
import { doc, setDoc } from 'firebase/firestore';
function SigunUpPage() {
	const [showPassword, setShowPassword] = useState(false);
	const [data, setData] = useState({})
	const [loading, setLoading] = useState(false);
	const value = useContext(GlobalContext)
	const navigate = useNavigate()
	const pendingLocation = localStorage.getItem('pendingLocation');

	const handleSignUp = () => {
		setLoading(true);
		createUserWithEmailAndPassword(auth, data.email, data.password)
			.then((userCredential) => {
				const user = userCredential.user;
				setDoc(doc(db, "users", user.uid), {
					email: data.email,
					name: `${data.firstName}  ${data.lastName}`,
					owner: [],
					projects: [],
					users: [],
					phone: data.phone,
					updatedBy: user.uid,
					updatedAt: new Date(),

				}).then(()=>{
					setDoc(doc(db, 'customers',`${value?.webConfig?.companiesId}-${user.uid}` ),{
						companiesId: value?.webConfig?.companiesId,
						name: `${data.firstName}  ${data.lastName}`,
						purchases:[],
						uid: user.uid
					})
				})
				if (!pendingLocation) return navigate('/');
				// if (pendingCourse) return navigate('//'+pendingCourse);


				window.location.href = (JSON.parse(pendingLocation)
					?.replace("digitalmarketing.localhost:3000", "")
					?.replace("digitalmarketing.deoapp.site", "")
					?.replace("http://digitalmarketing.deoapp.site", "")
					?.replace("https://digitalmarketing.deoapp.site", "")
				)
			})
			.catch((error) => {
				value.handleToast(`${error.code}`, `${error.message}`, 'error')
			}).finally(()=>{
				setLoading(false);
			});
	}

	return (
		<Flex
			minH={'90vh'}
			align={'center'}
			justify={'center'}
		>
			<Stack spacing={8} mx={'auto'} maxW={'lg'} py={12} px={6}>
				<Stack spacing="6">
					<Stack
						spacing={{
							base: '2',
							md: '3',
						}}
						textAlign="center"
					>
						<Heading
							size={{
								base: 'xs',
								md: 'sm',
							}}
						>
							Sign in to your account
						</Heading>
						<HStack spacing="1" justify="center">
							<Text color="muted">Have an account?</Text>
							<Link to='/authentication/login'>
								<Button variant="link" colorScheme={value.webConfig.colorScheme}>
									Log in
								</Button>
							</Link>
						</HStack>
					</Stack>
				</Stack>
				<Box
					rounded={'lg'}
					bg={useColorModeValue('white', 'gray.700')}
					boxShadow={'lg'}
					p={8}>
					<Stack spacing={4}>
						<HStack>
							<Box>
								<FormControl id="firstName" isRequired>
									<FormLabel>First Name</FormLabel>
									<Input type="text" onChange={e => setData({ ...data, firstName: e.target.value })} />
								</FormControl>
							</Box>
							<Box>
								<FormControl id="lastName" isRequired>
									<FormLabel>Last Name</FormLabel>
									<Input type="text" onChange={e => setData({ ...data, lastName: e.target.value })} />
								</FormControl>
							</Box>
						</HStack>
						<FormControl id="email" isRequired>
							<FormLabel>Phone number</FormLabel>
							<Input type="number" onChange={e => setData({ ...data, phone: e.target.value })} />
						</FormControl>
						<FormControl id="email" isRequired>
							<FormLabel>Email address</FormLabel>
							<Input type="email" onChange={e => setData({ ...data, email: e.target.value })} />
						</FormControl>
						<FormControl id="password" isRequired>
							<FormLabel>Password</FormLabel>
							<InputGroup>
								<Input type={showPassword ? 'text' : 'password'} onChange={e => setData({ ...data, password: e.target.value })} />
								<InputRightElement h={'full'}>
									<Button
										variant={'ghost'}
										onClick={() =>
											setShowPassword((showPassword) => !showPassword)
										}>
										{showPassword ? <FiEye size='lg' /> : <FiEyeOff />}
									</Button>
								</InputRightElement>
							</InputGroup>
						</FormControl>
						<Stack spacing={10} pt={2}>
							<Button
								loadingText="Submitting"
								size="lg"
								bg={'blue.400'}
								color={'white'}
								isLoading={loading}
								isDisabled={loading}
								_hover={{
									bg: 'blue.500',
								}}
								onClick={() => handleSignUp()}>
								Sign up
							</Button>
						</Stack>
						<Stack pt={6}>
							<Text align={'center'}>
								Already a user? <Link to='/authentication/login'>Login</Link>
							</Text>
						</Stack>
					</Stack>
				</Box>
			</Stack>
		</Flex>
	)
}

export default SigunUpPage