import React from "react";
import {
  Box,
  Heading,
  SimpleGrid,
  HStack,
  Flex,
  VStack,
  Text,
  Stack,
  Spacer,
} from "@chakra-ui/layout";
import moment from "moment";
import { PriceTag } from "../../Components/Carts/CartDrawer/PriceTag";
import { Link, useNavigate } from "react-router-dom";
import { MdLocationOn } from "react-icons/md";
import { Icon, Image, useColorModeValue } from "@chakra-ui/react";

const TicketCard = ({ item }) => {
  const navigate = useNavigate();
  const fontCol = useColorModeValue("blackAlpha.700", "whiteAlpha.700");
  const monthNames = moment.monthsShort();

  return (
    <Link to={`${item?.id}`}>
      <Box p="2" shadow="base" borderRadius="md" h="100%" position="relative">
        <Image
          src={item?.thumbnail || item?.thumbnaill}
          aspectRatio={16 / 9}
          objectFit={"cover"}
        />
        <Heading my="2" fontSize="md" noOfLines={2}>
          {item?.title}
        </Heading>
        <Stack align={"left"} justify={"right"} gap="1" h={120}>
          <Flex>
            {item?.dateEnd ? (
              <Text color={fontCol} fontSize={"md"}>
                {moment(item?.dateStart).format("DD")}{" "}
                {monthNames[moment(item?.dateStart).month()]} -{" "}
                {moment(item?.dateEnd).format("DD")}{" "}
                {monthNames[moment(item?.dateEnd).month()]}{" "}
                {moment(item?.dateEnd).format("YYYY")}
              </Text>
            ) : (
              <Text color={fontCol} fontSize={"md"}>
                {moment(item?.dateStart).format("DD")}{" "}
                {monthNames[moment(item?.dateStart).month()]}{" "}
                {moment(item?.dateStart).format("YYYY")}
              </Text>
            )}
          </Flex>

          <Text textTransform={"capitalize"} color={fontCol} fontSize={"sm"}>
            {item?.ticketType}
          </Text>
          {item?.eventType?.map((items, id) => {
            if (item?.id !== "FHFdfaoIpGKOYaOSnBwz")
              return (
                <HStack key={id} wordBreak={"break-word"}>
                  <Icon as={MdLocationOn} boxSize={5} color={"black"} />
                  <Heading fontSize="sm" color={fontCol} cursor={"pointer"}>
                    {" "}
                    {items === "offline" ? item?.address : "Zoom"} ({items}){" "}
                  </Heading>
                </HStack>
              );
          })}
          <Spacer />
          <Stack spacing={1}>
            <Text textAlign="end" color={fontCol} fontSize={"sm"}>
              start from
            </Text>
            <Flex justify={"right"} flexWrap={"wrap"}>
              <PriceTag price={item?.price} />
            </Flex>
          </Stack>
        </Stack>
        {/* <Text mb='2' fontSize={'sm'} color='gray.500' noOfLines={3}>{item?.description}</Text> */}

        {/* <Text fontSize='2xs'>Tags</Text> */}
        {/* <Flex overflowY={'auto'} css={{
                                        '&::-webkit-scrollbar': {
                                             height: '0rem',
                                             width: '4px',
                                        },
                                        '&::-webkit-scrollbar-track': {
                                             width: '6px',
                                             // backgroundColor: 'whitesmoke'
                                        },
                                        '&::-webkit-scrollbar-thumb': {
                                             // background: 'DarkGray',
                                             height: '2px',
                                             // borderRadius: '24px',
                                        },
                                   }} >
                                        {listing?.category?.map((x, i) => (
                                             <Badge key={i} fontSize='2xs' mx='1' colorScheme='green'>{x}</Badge>
                                        ))}
                                   </Flex> */}
      </Box>
    </Link>
  );
};

export default TicketCard;
