import React from 'react';
import { Route, Routes } from 'react-router-dom';
import CartMicroSite from '../Pages/Cart/CartMicroSite';
import MicroCheckoutPage from '../Pages/Checkout/MicroCheckoutPage';
import MicroFullLayout from '../Layouts/MicroFullLayout';

function  CartRoutes() {
	return (
		<Routes>
			<Route
				path="/"
				element={
				<MicroFullLayout>
					<CartMicroSite />
					</MicroFullLayout>
			}
			/>
			<Route
				path="/checkout"
				element={
					<MicroFullLayout>
						<MicroCheckoutPage />
					</MicroFullLayout>
				}
			/>

			{/* <Route path="/:id" element={<MicroHeaderLayout>< SingleCourseSite /></MicroHeaderLayout>} />
			<Route path="/:id/lesson/:lessonId" element={<MicroHeaderLayout>< SingleLessonSite /></MicroHeaderLayout>} /> */}

			{/* <Route path="setting/*" element={<SettingRouter />} /> */}
			{/* <Route path="example/*" element={<ExampleRouter />} /> */}
		</Routes>
	)
}

export default CartRoutes