import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Center,
  Container,
  Divider,
  Flex,
  Heading,
  HStack,
  Image,
  SimpleGrid,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";
import { CarouselComponent } from "../../Components/Galleries/Carousel/CarouselComponent";
import { getSingleDocumentFirebase } from "../../Api/firebaseApi";
import { Link, useNavigate, useParams } from "react-router-dom";
import { PriceTag } from "../../Components/Carts/CartDrawer/PriceTag";
import { FcBusinessContact } from "react-icons/fc";
import { GlobalContext } from "../../Hooks/Contexts/GlobalContext";
import { IoCart, IoCartOutline, IoLogoWhatsapp } from "react-icons/io5";
import { auth, db } from "../../Config/firebase";
import { arrayUnion, doc, setDoc, updateDoc } from "firebase/firestore";

const SingleProductMicroSite = () => {
  const [data, setData] = useState(null);
  const [alert, setAlert] = useState(false);

  const navigate = useNavigate();

  const params = useParams();
  const user = auth.currentUser;
  const value = useContext(GlobalContext);
  const { handleToast } = useContext(GlobalContext);

  const getData = async () => {
    const res = await getSingleDocumentFirebase("listings_product", params.id);
    let images = [];
    images.push(res.image);
    const newData = {
      ...res,
      images: images,
    };
    console.log(newData);

    setData(newData);
  };

  const handleAddToCart = async () => {
    //check if user loggedin ?
    if (user) {
      const newCart = value?.cart?.carts;
      const findItemIndex = newCart?.findIndex((item) => item.id === params.id);

      if (findItemIndex === -1 || !newCart || !findItemIndex) {
        setDoc(
          doc(db, "carts", `${value?.webConfig?.projectsId}-${user?.uid}`),
          {
            carts: arrayUnion({
              id: params.id,
              name: data.title,
              price: data.price,
              image: data.image,
              type: data.type,
              // link: courseDetail.link,
              qty: 1,
            }),
          },
          { merge: true }
        ).then(() => {
          setAlert(true);
        });
      } else {
        const existingItem = newCart[findItemIndex];
        const updatedQuantity = existingItem.qty + 1;

        updateDoc(
          doc(db, "carts", `${value?.webConfig?.projectsId}-${user?.uid}`),
          {
            [`newCart.${findItemIndex}.qty`]: updatedQuantity,
          }
        ).then(() => {
          handleToast(
            "Cart",
            `${data.title} successfully added to your cart`,
            "success"
          );
        });
      }
    } else {
      handleToast(
        "Sign Up / Login",
        "You must have an accout to purchase this course",
        "warning"
      );
      navigate("/authentication/login", { replace: true });
    }
  };

  const isLink = (value) => {
    const pattern = /^https?:\/\//i;
    return pattern.test(value);
  };

  const renderValue = (detail) => {
    if (isLink(detail.value)) {
      return (
        <a href={detail.value} target="_blank" rel="noopener noreferrer">
          <Text fontStyle={"italic"} color="blue.600" fontWeight={500}>
            Click here
          </Text>
        </a>
      );
    } else {
      return (
        <Text fontSize="sm" textTransform="capitalize">
          {detail.value}
        </Text>
      );
    }
  };

  const handleBuyNow = async (price) => {
    console.log(price, "ini price");
    if (!auth?.currentUser) {
      // localStorage.setItem("pendingCourse", courseDetail?.id);
      localStorage.setItem(
        "pendingLocation",
        JSON.stringify(window.location.href)
      );
      handleToast(
        "Please Login / Signup",
        "Please login to Purchase Course.",
        "warning"
      );
      navigate("/authentication/login");
    } else {
      handleAddToCart();
      navigate("/cart/checkout", { state: price });
    }
    // onOpen();
  };

  useEffect(() => {
    getData();

    return () => {
      setData();
    };
  }, []);

  return (
    <>
      <>
        <Box pos="relative">
          {<CarouselComponent images={data?.images} aspectRatio={16 / 9} />}
          <Box m="2" p="2" w="100%">
            <Text>Start From</Text>
            <HStack w="100%">
              <Heading fontSize="lg">
                {<PriceTag price={data?.price} />}
              </Heading>
              {data?.priceEnd && (
                <Flex align="center">
                  <Heading fontSize="lg" mr="2">
                    -
                  </Heading>
                  <Heading fontSize="lg">
                    {<PriceTag price={data?.priceEnd} />}
                  </Heading>
                </Flex>
              )}
            </HStack>
          </Box>

          <Divider />
          <Stack py="5" px="2">
            <Flex justify={"space-between"}>
              <Heading>{data?.title}</Heading>
              {data?.logo && (
                <Image
                  src={data?.logo}
                  aspectRatio={16 / 9}
                  w="100px"
                  h="50px"
                  objectFit={"contain"}
                />
              )}
            </Flex>
            <Text>{data?.description}</Text>
          </Stack>

          <Accordion allowToggle>
            <AccordionItem>
              <AccordionButton>
                <Box as="span" flex="1" textAlign="left">
                  Detail Product
                </Box>
                <AccordionIcon />
              </AccordionButton>

              <AccordionPanel pb={4}>
                {data?.details?.map((detail, index) => (
                  <HStack key={index} spacing={2} alignItems="center">
                    <Text
                      fontSize="sm"
                      // maxW={"500px"}
                      textTransform="capitalize"
                      fontWeight="bold"
                    >
                      {detail.key}:
                    </Text>
                    {/* <Spacer /> */}
                    {renderValue(detail)}
                  </HStack>
                ))}
              </AccordionPanel>
            </AccordionItem>
          </Accordion>

          <Divider />

          <SimpleGrid columns={2} m="2" my="4">
            {data?.details?.map((x, i) => (
              <Flex boxShadow={"sm"} borderRadius="md" m="1" p="1" key={i}>
                <Text pr="1" mt="2">
                  <FcBusinessContact fontSize={"27px"} />
                </Text>
                <VStack spacing={2} justify={"left"} align={"left"} p="1.5">
                  <Text textTransform={"capitalize"} fontWeight={"medium"}>
                    {x.key}
                  </Text>
                  {x?.value?.includes("https://") ? (
                    <Link to={`${x.value}`} target="_blank">
                      <Text color="blue">Click here</Text>
                    </Link>
                  ) : (
                    <Text overflowWrap={"break-word"} w={"148px"}>
                      {x.value}
                    </Text>
                  )}
                </VStack>
              </Flex>
            ))}
          </SimpleGrid>

          <HStack>
            {/* <Box position="sticky" bottom="0" width="full" p="2">
              <Button
                colorScheme={value?.webConfig?.colorScheme}
                w="full"
                leftIcon={<IoLogoWhatsapp />}
                onClick={() => handleBuyNow(data?.price)}
              >
                Buy Now
              </Button>
            </Box> */}
            <Box position="sticky" bottom="0" width="full" p="2">
              {/* <Button colorScheme={value?.webConfig?.colorScheme} w='full' leftIcon={<IoLogoWhatsapp />}>Contact seller</Button> */}
              <Button
                colorScheme={value?.webConfig?.colorScheme}
                w="full"
                leftIcon={<IoCartOutline />}
                onClick={handleAddToCart}
              >
                Add To Cart
              </Button>
            </Box>
          </HStack>
        </Box>
      </>

      <AlertDialog
        motionPreset="slideInBottom"
        // leastDestructiveRef={cancelRef}
        onClose={() => setAlert(false)}
        isOpen={alert}
        isCentered
      >
        <AlertDialogOverlay />

        <AlertDialogContent>
          <AlertDialogHeader>Cart</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>{data?.title} added to your cart.</AlertDialogBody>
          <AlertDialogFooter>
            {/* <Button ref={cancelRef} onClick={onClose}>
                        No
                        </Button> */}
            <Button colorScheme="red" ml={3} onClick={() => navigate("/cart")}>
              Go to My Cart
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};

export default SingleProductMicroSite;
