import { Avatar, Box, Button, Divider, Flex, HStack, Heading, SimpleGrid, Spinner, StackDivider, Switch, Text, VStack, useDisclosure } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import Modals from '../../Components/Modals/Modals'
import { getSingleDocumentFirebase } from '../../Api/firebaseApi'
import { auth } from '../../Config/firebase'

const Profile = () => {
     const uid = auth?.currentUser.uid
     const { isOpen, onClose, onOpen } = useDisclosure()
     const [data, setData] = useState(null)
     const [notif, setNotif] = useState(null)

     const [loading, setLoading] = useState(false)

     const handleModal = (type) => {
          onOpen()
          setData({ ...data, type: type })
     }

     const getData = async () => {
          const res = await getSingleDocumentFirebase('users', uid)
          setData(res)
     }

     useEffect(() => {
          getData()
          return () => {
               setData();
          };
     }, [])
     return (

          <Box h='100%'>

               <SimpleGrid columns='3' bgColor='brand.1' p='2'>
                    <Heading size="md" color={'black'}>Profile</Heading>
               </SimpleGrid>

               <Box p='2' mt='2'>
                    <VStack border='1px solid gray' align='left' borderRadius='3px'
                         divider={<StackDivider />}
                    >
                         <Flex justify='space-between' align='center' p='2'>
                              <VStack align='left'>
                                   <Text>Full Name</Text>
                                   <Text>{data?.name}</Text>
                              </VStack>

                              <Text cursor='pointer' onClick={() => handleModal('name')}>Edit</Text>

                         </Flex>
                         <Flex justify='space-between' align='center' p='2'>
                              <VStack align='left'>
                                   <Text>Email</Text>
                                   <Text>{data?.email}</Text>
                              </VStack>
                              {/* <Text cursor='pointer' onClick={() => handleModal('email')}>Edit</Text> */}
                         </Flex>
                         <Flex justify='space-between' align='center' p='2'>
                              <VStack align='left'>
                                   <Text>Password</Text>
                                   <Text>*********</Text>
                              </VStack>
                              <Text cursor='pointer' onClick={() => handleModal('password')}>Change</Text>
                         </Flex>
                    </VStack>
                    <Box mt='5' mb='2'>
                         <Text mb='2'>Profile Image</Text>
                         <HStack>
                              {loading ? <Spinner /> : <Avatar size='lg' name={data?.name} src={data?.image? data?.image : null} />}
                              {data?.image ?
                                   <Button colorScheme='red' onClick={() => handleModal('confirm-delete')} >Delete</Button> : <Button colorScheme='blue' onClick={() => handleModal('photo')}>Upload</Button>
                              }
                         </HStack>
                    </Box>
               </Box>
{/* 
               <SimpleGrid columns='3' bgColor='brand.1' p='2' color={'black'}>
                    <Heading size="md">Notification</Heading>
               </SimpleGrid>
               <Divider />
               <Box p='2'>

                    <VStack border='1px solid gray' mt='2' align='left' borderRadius='3px'
                         divider={<StackDivider />}
                    >
                         <HStack p='2'>
                              <Switch onChange={(e) => setNotif({ ...notif, comment: e.target.checked })} value='response-comment' />
                              <Text> Email me when someone responds to my comments.</Text>
                         </HStack>
                         <HStack p='2'>
                              <Switch onChange={(e) => setNotif({ ...notif, discussion: e.target.checked })} value='response-discussion' />
                              <Text> Email me when someone comments on a discussion I've commented in.</Text>
                         </HStack>
                         <HStack p='2'>
                              <Switch onChange={(e) => setNotif({ ...notif, promotion: e.target.checked })} value='promotion' />
                              <Text>Yes, Code with Mosh can email me with promotions and news. (optional).</Text>
                         </HStack>
                    </VStack>
               </Box> */}
               <Modals isOpen={isOpen} onClose={onClose} data={data} getData={getData}  setLoading={setLoading} />
          </Box>

     )
}

export default Profile