import { Box, Heading } from '@chakra-ui/react'
import React from 'react'

function Error404() {
	return (
		<Box height='90vh'>
			<Heading textAlign='center' alignSelf='center'>404 Error</Heading>
		</Box>
	)
}

export default Error404