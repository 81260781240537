import { Box, Button, FormControl, FormLabel, Heading, Input, Textarea } from '@chakra-ui/react'
import React from 'react'

const Contact = () => {
     return (
          <Box>
               <Heading p='2'>Contact</Heading>
               <Box p='2'>
                    <FormControl>
                         <FormLabel>Contact</FormLabel>
                         <Input type='text' />
                    </FormControl>
                    <FormControl>
                         <FormLabel>Message</FormLabel>
                         <Textarea />
                    </FormControl>
                    <Button my='2'>Send</Button>
               </Box>
          </Box>
     )
}

export default Contact