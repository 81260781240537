import axios from "axios";

export const finpayCreatePayment = async (axiosData) => {
    const url = "https://asia-southeast2-deoapp-indonesia.cloudfunctions.net/finpayCreatePayment";
    const options = {
        headers: {
            "Content-Type": "application/json",
            "Authorization": "pFa08EJkVRoT7GDiqk1",
        }
    };

    try {
        const res = await axios.post(url, axiosData, options);
        if (res) return res;
    } catch (error) {
        throw new Error(error.message)
    };
};

export const createIpaymuPayment = async (axiosData) => {
    // console.log(axiosData)
    const url = "https://asia-southeast2-deoapp-indonesia.cloudfunctions.net/ipaymuCreatePayment";
    const options = {
        headers: {
            "Content-Type": "application/json",
            "Authorization": "pFa08EJkVRoT7GDiqk1",
        }
    };

    try {
        const res = await axios.post(url, axiosData, options);
        if (res) return res;
    } catch (error) {
        throw new Error(error.message)
    };
};