import { Badge, Box, Center, Container, Flex, Icon, SimpleGrid } from '@chakra-ui/react'
import React, { useContext, useEffect } from 'react'
import { FiBookOpen, FiHome, FiMenu, } from 'react-icons/fi'
import { AiOutlineInbox} from 'react-icons/ai'
import { Link } from 'react-router-dom'
import { GlobalContext } from '../../Hooks/Contexts/GlobalContext'
import { CircularMenuComponent } from '../Buttons/CircularMenu/CircularMenuComponent'
import { BsPerson } from 'react-icons/bs';

function MicroNavbar(props) {
	const value = useContext(GlobalContext)
	const menu = [
		{ title: 'Home', icon: FiHome, link: '/' },
		{ title: 'News', icon: FiBookOpen, link: '/news' },
		{ title: 'Menu', icon: FiMenu, link: '/' },
		{ title: 'Orders', icon: AiOutlineInbox, link: '/account/order-history' },
		{ title: 'Account', icon: BsPerson, link: '/account' },
	]
	
	return (
		<Box {...props}>
			<SimpleGrid columns={5} alignContent='center'>
				{/* <Flex> */}
				{menu.map((x, i) => {
					if (i === 2)
						return (
							<Container key={i}  >
								<CircularMenuComponent data={value.webConfig.features} />
							</Container>
						)
					else if (i === 3)
						return (
							<Link key={i} to={x.link}>
								<Center >
									<Flex>
										<Icon as={x.icon} boxSize={6} color={'black'} />
										{/* <Box>
											<Badge size={'sm'} colorScheme='red' borderRadius='full' ml='-1' mt='-5'>{value?.cart?.carts ? value.cart.carts.length : 0}</Badge>
										</Box> */}
									</Flex>
								</Center>
								{/* <Text textAlign='center' fontSize='xs' >{x.title}</Text> */}
							</Link>
						)
					else
						return (
							<Link key={i} to={x.link} >
								<Center>
									<Icon as={x.icon} color={'black'} boxSize={6} />
								</Center>
								{/* <Text textAlign='center' fontSize='xs' >{x.title}</Text> */}
							</Link>
						)
				}
				)}
				{/* </Flex> */}
			</SimpleGrid>
		</Box >
	)
}

export default MicroNavbar