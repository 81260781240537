import { Badge, Box, Button, HStack, Heading, Icon, Image, Spacer, Stack, Tooltip, useColorMode } from '@chakra-ui/react'
import React, { useContext } from 'react'
import { FiBell, FiHeart, FiLogOut, FiMoon, FiShoppingCart, FiSun } from 'react-icons/fi'
import { Link, useNavigate } from 'react-router-dom'
import { logOutUser } from '../../Api/firebaseApi'
import { GlobalContext } from '../../Hooks/Contexts/GlobalContext'

function BasicHeaderComponent() {
	const value = useContext(GlobalContext);
	const navigate = useNavigate()

	const { colorMode, toggleColorMode } = useColorMode();
	const defaultLogo = require('../../Assets/deoapp_logo.png');


	return (
		<Box id="header" position='sticky' p={3} top={0} zIndex={100} width='full' shadow='md' bgColor={colorMode === 'light' ? 'white' : 'black'}>
			<HStack>
				<Link to='/'>
					<Image
						src={colorMode === 'light' ? value?.webConfig?.logoLight : colorMode === 'dark' ? value?.webConfig?.logoDark : defaultLogo}
						h='30px'
						alt='logo'
					/>
				</Link>
				<Spacer />
				{value.webConfig.name !== undefined && (
					value.auth ?
						<HStack p='2'>
							{value.webConfig.projectsId !== "PQwxxgMvG8ivkxyP5sHs" &&
								<>
									<Tooltip label='Cart'>
										<HStack bgColor='brand.1' w='10' h='10' p={5} borderRadius='full' alignItems='center' justifyContent='center' cursor={'pointer'} pos={'relative'} onClick={() => navigate('/cart')}>
											<Icon as={FiShoppingCart} boxSize={6} color={'black'} />
											<Box pos={'absolute'} right={'1'}>
												<Badge colorScheme='red' borderRadius='full' mt='-5'>{value?.cart?.carts ? value.cart.carts.length : 0}</Badge>
											</Box>
										</HStack>
									</Tooltip>
									<Tooltip label='Screen Mode'>
										<Stack bgColor='brand.1' w='10' h='10' borderRadius='full' alignItems='center' justifyContent='center' cursor={'pointer'}>
											<Icon as={colorMode === 'light' ? FiMoon : FiSun} w='6' h='6' color={'black'} onClick={toggleColorMode} />
										</Stack>
									</Tooltip>
								</>
							}
							{/* <Stack bgColor='brand.1' w='10' h='10' borderRadius='full' alignItems='center' justifyContent='center' >
			<Icon as={FiBell} w='6' h='6' color={'black'} />
		</Stack>
		<Stack bgColor='brand.1' w='10' h='10' borderRadius='full' alignItems='center' justifyContent='center' >
			<Icon as={FiHeart} w='6' h='6' color={'black'} />
		</Stack> */}
							<Tooltip label='Logout'>
								<HStack bgColor='brand.1' w='10' h='10' p={5} borderRadius='full' alignItems='center' justifyContent='center' cursor={'pointer'} onClick={() => { logOutUser(); navigate('/') }}>
									<Icon as={FiLogOut} w='6' h='6' color={'black'} />

								</HStack>
							</Tooltip>

						</HStack>
						:
						<>
							<Link to='/authentication/signup'>
								<Button size='xs' colorScheme={value.webConfig.colorScheme} variant='outline'>Signup</Button>
							</Link>
							<Link to='/authentication/login'>
								<Button size='xs' colorScheme={value.webConfig.colorScheme}>Login</Button>
							</Link>
						</>

				)}

			</HStack>
		</Box>
	)
}

export default BasicHeaderComponent