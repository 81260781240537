import { HStack, Image, Stack, Text, VStack } from "@chakra-ui/react";
import { PriceTag } from "./CartDrawer/PriceTag";
import { useNavigate } from "react-router-dom";

export const CheckoutDrawerComponent = (props) => {
  const { name, qty, image, price, id, type } = props;
  const navigate = useNavigate();

  return (
    <Stack direction="row" spacing="5" mx="4">
      <Image
        rounded="md"
        minWidth="24"
        maxWidth="24"
        height={{
          base: "20",
          md: "24",
        }}
        fit="cover"
        src={image}
        alt={name}
        draggable="false"
        loading="lazy"
        onClick={
          type === "product"
            ? () => navigate(`/product/${id}`)
            : () => navigate(`/course/${id}`)
        }
      />
      <Stack width="full" spacing="3">
        <Text fontWeight="medium">{name}</Text>
        <HStack>
          <Text>
            <PriceTag price={price} currency="IDR" />
          </Text>
          <Text fontSize={"sm"}>{"/"}each</Text>
        </HStack>
      </Stack>

      <VStack dir="column">
        <Text>Qty</Text>
        <Text>{qty}</Text>
      </VStack>
    </Stack>
  );
};
