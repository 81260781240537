import {
  Box,
  Button,
  Flex,
  Image,
  Skeleton,
  Text,
  VStack,
} from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";
import { GlobalContext } from "../../Hooks/Contexts/GlobalContext";
import { getCollectionFirebase } from "../../Api/firebaseApi";
import { useNavigate } from "react-router-dom";

function HomeCarousel(props) {
  const value = useContext(GlobalContext);
  const [data, setData] = useState([]);
  const navigate = useNavigate();

  const fetchContent = async (collectionName, conditions) => {
    const sortBy = { field: "title", direction: "asc" };
    const limitValue = 10;
    const startAfterData = null;

    const res = await getCollectionFirebase(collectionName, { conditions }, { sortBy }, { limitValue }, { startAfterData });
    return res;
  };

  useEffect(() => {
    const fetchDataBasedOnType = async () => {
      if (!props.type || !value) return;

      if (value.webConfig.projectsId) {
        let content = null;
        const conditions = [
          { field: 'projectId', operator: '==', value: value.webConfig.projectsId }
        ];
        const conditionsCourses = [{ field: 'projectsId', operator: '==', value: value.webConfig.projectsId }];


        if (props.type === 'listing') {
          content = await fetchContent('listings', conditions);
        } else if (props.type === 'ticket') {
          content = await fetchContent('tickets', conditions);
        } else if (props.type === 'course') {
          content = await fetchContent('courses', conditionsCourses);
        }

        const contentSort = content?.sort((a, b) => a.title.localeCompare(b.title))
        setData(contentSort);
      }

    };

    fetchDataBasedOnType();
  }, [props.type, value]);

  const handleClick = (data) => {
    const typeToPath = {
      course: "course",
      news: "news",
      listing: "listing/view",
      ticket: "ticket",
    };

    const path = typeToPath[props.type];
    navigate(`${path}/${data.id}`);
  };

  return (
    <Flex overflowY="auto" paddingBottom={2} css={{
      '&::-webkit-scrollbar': {
        height: '0.5rem',
        width: '4px',
        display: 'none'
      },
      '&::-webkit-scrollbar-track': {
        width: '6px',
        backgroundColor: 'whitesmoke',
        display: 'none'
      },
      '&::-webkit-scrollbar-thumb': {
        background: 'DarkGray',
        height: '2px',
        borderRadius: '24px',
        display: 'none'
      },
    }}>
      {data?.length > 0 ? (
        data.map((x, i) => (
          <Box
            key={i}
            shadow="base"
            p="0"
            mx="2"
            minW="40%"
            maxW="200px"
            minH="260px"
            maxH="280px"
            rounded={5}
            pos="relative"
          >
            <Image
              roundedTop={5}
              objectFit="cover"
              src={x.image || x.thumbnail}
              alt={x.title}
              h="100px"
              w="100%"
              fallback={<Skeleton />}
            />

            <VStack maxH="50%" px={2} w="100%" align="left">
              <Text fontSize="sm" noOfLines="2" as="b">
                {x?.title}
              </Text>
              <Text
                fontSize="xs"
                color={props.fontCol}
                noOfLines={x.author ? "2" : "3"}
                my="1"
              >
                {x?.description}
              </Text>
            </VStack>

            <Flex
              align="center"
              px={1}
              py={2}
              pos="absolute"
              bottom={0}
              w="100%"
            >
              <VStack align="left" w="100%">
                <Text fontSize="sm" fontWeight="medium" textAlign="right">
                  {x?.author}
                </Text>
                <Button
                  w="full"
                  size="xs"
                  colorScheme={value?.webConfig?.colorScheme}
                  onClick={() => handleClick(x)}
                >
                  View
                </Button>
              </VStack>
            </Flex>
          </Box>
        ))
      ) : (
        <Text textAlign="center" fontSize="sm" mt="4" color="gray.500">
          There is no {props?.type}
        </Text>
      )}
    </Flex>
  );
}

export default HomeCarousel;
