import { Box, Center, Heading, Image, SimpleGrid, Text, VStack, useColorModeValue } from '@chakra-ui/react'
import React from 'react'

const Subsriptions = () => {
     const fontCol = useColorModeValue('blackAlpha.700', 'whiteAlpha.700')

     const contentPaid = [
          { id: 0, title: 'Course 1', description: 'Description course 1', price: 'Rp100.000', author: 'Author 1', image: 'https://images.unsplash.com/photo-1602024242516-fbc9d4fda4b6?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1500&q=80', },
          { id: 1, title: 'Course 2', description: 'Description course 2', price: 'Rp200.000', author: 'Author 2', image: 'https://images.unsplash.com/photo-1602024242516-fbc9d4fda4b6?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1500&q=80', },
          { id: 2, title: 'Course 3', description: 'Description course 3', price: 'Rp300.000', author: 'Author 3', image: 'https://images.unsplash.com/photo-1602024242516-fbc9d4fda4b6?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1500&q=80', },
          { id: 3, title: 'Course 4', description: 'Description course 4', price: 'Rp400.000', author: 'Author 4', image: 'https://images.unsplash.com/photo-1602024242516-fbc9d4fda4b6?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1500&q=80', },
          { id: 4, title: 'Course 5', description: 'Description course 5', price: 'Rp500.000', author: 'Author 5', image: 'https://images.unsplash.com/photo-1602024242516-fbc9d4fda4b6?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1500&q=80', },
          { id: 5, title: 'Course 6', description: 'Description course 6', price: 'Rp600.000', author: 'Author 6', image: 'https://images.unsplash.com/photo-1602024242516-fbc9d4fda4b6?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1500&q=80', }
     ]
     return (
          <Box>
               <SimpleGrid columns='1' bgColor='brand.1' p='2'>
                    <Heading size="md" color={'black'}>Your Active Subscriptions</Heading>
               </SimpleGrid>
               <SimpleGrid columns={contentPaid.length === 1 ? 1 : 2} spacing='2' my='2' w='100%'>
                    {contentPaid.map((item, index) => (

                         <Box key={index} shadow='base' mx='2' my='2' minH='260px' maxH='280px' rounded={5} pos={'relative'}>
                              <Box>
                                   <Image roundedTop={5} objectFit="cover" src={item.image || item.thumbnail} alt={item.title} h='100px' w='100%' />
                              </Box>

                              <VStack maxH={"50%"} px={2} w='100%' align={'left'}>
                                   <Text fontSize={'md'} noOfLines={'2'} as={'b'} >{item.title}</Text>
                                   <Text fontSize={'sm'} color={fontCol} noOfLines={item.author ? '2' : '3'} my='1'>{item.description}</Text>
                              </VStack>

                              <Center px={1} py={2} pos={'absolute'} bottom={0} w='100%'>
                                   <VStack align={'left'} w='100%'>
                                        <Text fontSize={'sm'} fontWeight={'medium'} textAlign={'right'}>{item.author}</Text>
                                        <Text fontSize={'sm'} fontWeight={'medium'} textAlign={'right'}>{item.price}</Text>

                                   </VStack>
                              </Center>
                         </Box>

                    ))}
               </SimpleGrid>

          </Box>
     )
}

export default Subsriptions