import {
    Box,
    Button,
    Flex,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
    Heading,
    HStack,
    Grid,
    Text,
    Stack,
    AspectRatio,
    useColorModeValue,
    IconButton
} from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";
import { arrayUnion, doc, setDoc, updateDoc } from "firebase/firestore";
import { auth, db } from "../../Config/firebase";
import { useNavigate, useParams } from "react-router-dom";
import ReactPlayer from "react-player";
import parse from 'html-react-parser';
import { getCollectionFirebase, getCollectionWhereFirebase, getSingleDocumentFirebase } from "../../Api/firebaseApi";
import { GlobalContext } from "../../Hooks/Contexts/GlobalContext";
import { FiLock } from "react-icons/fi";
import { FaPlay } from "react-icons/fa";
import { PriceTag } from "../../Components/Carts/CartDrawer/PriceTag";


const PromptListPage = () => {
    const params = useParams();
    const user = auth.currentUser
    const value = useContext(GlobalContext);
    const { handleToast } = useContext(GlobalContext);

    const bgCol = useColorModeValue('white', 'gray.800')
    const fontCol = useColorModeValue('blackAlpha.700', 'whiteAlpha.700')

    const [noOfLines, setNoOfLines] = useState(5);
    const [courseDetail, setCourseDetail] = useState({});

    const [lessons, setLessons] = useState(null);
    const [purchased, setPurchased] = useState([])

    const navigate = useNavigate();
    const getCourseDetail = async () => {
        const res = await getSingleDocumentFirebase('courses', params.courseId);
        if (res && res.courseType === 'docs_course') {
            const listFolder = await getCollectionWhereFirebase('folders', '__name__', 'in', res.folderId)
            if (listFolder) {
                setCourseDetail({ ...res, sections: listFolder });
            } else {
                setCourseDetail(res);
            }
        }
    };

    const getLessons = async () => {
        try {
            const files = await getCollectionWhereFirebase('files', 'companyId', '==', value?.webConfig?.companiesId)
            setLessons(files)
        } catch (error) {
            console.log(error.message, "error getting prompt files...")
        }

    };

    const getCustomer = async () => {
        const conditions = [
            { field: 'userId', operator: '==', value: auth?.currentUser?.uid },
            { field: 'projectId', operator: '==', value: value?.webConfig?.projectsId },
            { field: 'paymentStatus', operator: '==', value: 'PAID' },
        ];

        try {
            const res = await getCollectionFirebase('orders', { conditions }, { sortBy: null }, { limitValue: null });
            if (res) {
                res?.map((c) => {
                    c?.orders?.map((y) => {
                        if (y.id === params.courseId) {
                            setPurchased(y);
                        }
                    })
                })
            }


            // Set the updated res to the state variable 'purchased'
        } catch (error) {
            console.log('Error fetching customer data:', error.message);
            // Handle the error appropriately, e.g., show an error message to the user.
        }
    };
    const handleAddToCart = async () => {
        //check if user loggedin ?
        if (user) {
            const newCart = value?.cart?.carts;
            const findItemIndex = newCart?.findIndex(item => item.id === params.courseId);

            console.log({ findItemIndex });
            console.log({ newCart });

            if (findItemIndex === -1 || !newCart || !findItemIndex) {
                console.log("adding new item to cart")
                setDoc(
                    doc(db, "carts", `${value?.webConfig?.projectsId}-${user?.uid}`),
                    {
                        carts: arrayUnion({
                            id: params.courseId,
                            name: courseDetail.title,
                            price: courseDetail.price,
                            image: courseDetail.thumbnail,
                            // link: courseDetail.link,
                            qty: 1
                        }),
                    },
                    { merge: true }
                ).then(() => {
                    handleToast(
                        "Keranjang",
                        `Berhasil menambahkan ${courseDetail.title} ke keranjang kamu`,
                        "success"
                    );
                })
            } else {
                const existingItem = newCart[findItemIndex];
                const updatedQuantity = existingItem.qty + 1;
                console.log("adding existing item to cart")

                updateDoc(
                    doc(db, "carts", `${value?.webConfig?.projectsId}-${user?.uid}`),
                    {
                        [`newCart.${findItemIndex}.qty`]: updatedQuantity,
                    }
                ).then(() => {
                    handleToast(
                        "Keranjang",
                        `Berhasil menambahkan ${courseDetail.title} ke keranjang kamu`,
                        "success"
                    );
                })



                // setDoc(
                // 	doc(db, "carts", `${value?.webConfig?.projectsId}-${user?.uid}`),
                // 	// {
                // 	// 	carts: arrayUnion({
                // 	// 		id: params.courseId,
                // 	// 		name: courseDetail.title,
                // 	// 		price: courseDetail.price,
                // 	// 		image: courseDetail.thumbnail,
                // 	// 		// link: courseDetail.link,
                // 	// 		qty: value?.cart?.carts[newCart]?.qty + 1
                // 	// 	}),
                // 	// },
                // 	{
                // 		carts: arrayRemove({
                // 			id: params.courseId,
                // 			name: courseDetail.title,
                // 			price: courseDetail.price,
                // 			image: courseDetail.thumbnail,
                // 			// link: courseDetail.link,
                // 			qty: value?.cart?.carts[newCart]
                // 		})
                // 	},
                // 	{ merge: true }
                // ).then(() => {
                // 	setDoc(
                // 		doc(db, "carts", `${value?.webConfig?.projectsId}-${user?.uid}`),
                // 		{
                // 			carts: arrayUnion({
                // 				id: params.courseId,
                // 				name: courseDetail.title,
                // 				price: courseDetail.price,
                // 				image: courseDetail.thumbnail,
                // 				// link: courseDetail.link,
                // 				qty: value?.cart?.carts[newCart]?.qty + 1
                // 			}),
                // 		}, { merge: true }).then(() => {
                // 			handleToast(
                // 				"Keranjang",
                // 				`Berhasil menambahkan ${courseDetail.title} ke keranjang kamu`,
                // 				"success"
                // 			);
                // 		})
                // })
            }
        } else {
            // toast(
            // 	"Daftar / Login",
            // 	"Kamu harus mendaftarkan diri untuk membeli produk ini",
            // 	"warning"
            // );
            navigate("/authentication/login", { replace: true });
        }
    };

    const navigateLogin = () => {

			// localStorage.setItem("pendingCourse", courseDetail?.id);
			localStorage.setItem("pendingLocation", JSON.stringify(window.location.href));
			handleToast("Please Login / Signup", "Please login to Purchase Course.", "warning");
			navigate("/authentication/login");
    };

    useEffect(() => {
        getCourseDetail();
        getLessons();
        getCustomer()
    }, []);

    if (courseDetail?.courseType === "docs_course") return (
        <Box minH={'100%'} pos={'relative'}>
            <Box minH='87vh' maxW={'md'}>
                <Box>
                    {courseDetail?.videoThumbnail &&
                        <AspectRatio ratio={16 / 9}>
                            <ReactPlayer
                                width="full"
                                height='full'
                                controls={true}
                                playing
                                playIcon={<IconButton rounded='full' width={50} height={50} icon={<FaPlay />} />}
                                // light={overlay}
                                url={courseDetail?.videoThumbnail ? courseDetail?.videoThumbnail : "https://www.youtube.com/watch?v=d9MyW72ELq0&ab_channel=Avatar"}
                            />
                        </AspectRatio>
                    }
                    {courseDetail?.description ? (
                        <Box mx='5'>
                            <Box mt={5} noOfLines={noOfLines} fontSize={14}>
                                {parse(courseDetail?.description)}
                            </Box>
                            <Flex justifyContent='flex-end'>
                                <Button
                                    onClick={() => {
                                        if (noOfLines === 5) setNoOfLines(null)
                                        if (noOfLines === null) setNoOfLines(5)
                                    }}
                                    size='xs'
                                    variant='ghost'
                                >
                                    {noOfLines === 5 ? 'Read more...' : 'collapse'}
                                </Button>
                            </Flex>

                        </Box>
                    ) : null}

                    {/* {courseDetail?.sections ? (
					<SimpleGrid columns={1}>
					{courseDetail?.sections?.map((x, i) => (
						<SectionCardComponent
						key={i}
						data={x}
						type='section'
						cursor='pointer'
								_hover={{
									transform: 'scale(1.04)',
									transition: "60ms linear"
								}}
								/>
						))}
						</SimpleGrid>
				) : (
					<Center>
					<Spinner />
					</Center>
					)}
					
				<Modals isOpen={isOpen} onClose={onClose} data={data} />
			)} */}


                    <Box mx={4} my={5}>
                        <Accordion allowToggle>
                            {courseDetail?.sections?.map((section, i) => {
                                const numberOfLessons = lessons?.filter(item => item.folderId === section.id)?.length;
                                return (
                                    <AccordionItem key={i} bg={bgCol} my={2}>
                                        <h2>
                                            <AccordionButton>
                                                <Stack gap={2} as="span" flex='1' textAlign='left'>
                                                    <Heading size='sm'>{section.name}</Heading>
                                                    <HStack>
                                                        <Text color={fontCol} fontSize={14} size='md'>{numberOfLessons} lessons available</Text>
                                                    </HStack>
                                                </Stack>
                                                <AccordionIcon />
                                            </AccordionButton>
                                        </h2>
                                        <AccordionPanel pb={4}>
                                            <Grid gap={2}>
                                                {lessons?.filter(item => item.folderId === section.id).map((lesson, idx) => {
                                                    return (
                                                        <Box bg='blackAlpha.50' p={2} key={idx}>
                                                            <Flex justifyContent='space-between'>
                                                                <Text fontSize={13} fontWeight={500} color={fontCol}>{lesson?.heading}</Text>
                                                                {/* {purchased?.length > 0 ?  */}
                                                                {purchased.length !== 0 ?
                                                                    <Button
                                                                        size='sm'
                                                                        variant='outline'
                                                                        colorScheme={value?.webConfig?.colorScheme}
                                                                        onClick={() => navigate(`prompt-gpt/${lesson.id}`)}
                                                                    >
                                                                        Review
                                                                    </Button>
                                                                    :
                                                                    <FiLock />
                                                                }

                                                            </Flex>
                                                        </Box>
                                                    )
                                                })}
                                            </Grid>
                                        </AccordionPanel>
                                    </AccordionItem>
                                )
                            })}
                        </Accordion>
                    </Box>
                </Box>
            </ Box>
            <Box position='fixed' bg='white' bottom='0' p='2' w='md' >
                {courseDetail.priceType !== 'free' && user?.uid !== undefined && purchased?.length === 0 ?
                    <HStack gap='5' >

                        <Box fontSize={'lg'}>

                            <PriceTag price={courseDetail.price} />
                        </Box>
                        <Button
                            w='100%'
                            colorScheme={value.webConfig.colorScheme}
                            onClick={() => handleAddToCart()}
                        >
                            Add to Cart
                        </Button>
                    </HStack>
                    : courseDetail.priceType !== 'free' && user?.uid === undefined ?
                        <HStack gap='5' p='2' >
                            <Box fontSize={'lg'}>

                                <PriceTag price={courseDetail.price} />
                            </Box>
                            <Button w='100%' colorScheme={value.webConfig.colorScheme} onClick={navigateLogin}>Add To Cart</Button>
                        </HStack>
                        : <></>
                }
            </Box>
        </Box>
    )
}

export default PromptListPage;
