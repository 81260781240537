import {
  Badge,
  Box,
  Button,
  Center,
  Divider,
  Flex,
  Heading,
  HStack,
  Icon,
  IconButton,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  SimpleGrid,
  Spinner,
  Stack,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import {
  collection,
  doc,
  limit,
  onSnapshot,
  query,
  startAfter,
  where,
  getDocs,
  orderBy,
} from "firebase/firestore";
import React, { useContext, useEffect, useState } from "react";
import { FiSearch } from "react-icons/fi";
import { Link, useLocation } from "react-router-dom";
import { db } from "../../Config/firebase";
import { GlobalContext } from "../../Hooks/Contexts/GlobalContext";
import { getSingleDocumentFirebase } from "../../Api/firebaseApi";
import { PriceTag } from "../../Components/Carts/CartDrawer/PriceTag";
import { clientTypesense } from "../../Api/Typesense";
import BackButtons from "../../Components/Buttons/BackButtonComponent";

function ListingMicroSite() {
  const value = useContext(GlobalContext);
  const [categoryData, setCategoryData] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedCategoryNiche, setSelectedCategoryNiche] = useState("All");
  const param = useLocation();
  const [datas, setDatas] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isSearching, setIsSearching] = useState(false);

  const getListing = () => {
    const q = query(
      collection(db, "listings"),
      where("projectId", "==", value?.webConfig?.projectsId)
    );

    const unsubscribe = onSnapshot(q, (snapshot) => {
      const data = [];
      snapshot.forEach((doc) => {
        const docData = doc.data();
        data.push({ id: doc.id, ...docData });
      });

      const mappedData = data.reduce((acc, listing) => {
        listing.category.forEach((category) => {
          acc[category] = acc[category] || [];
          acc[category].push(listing);
        });
        return acc;
      }, {});

      setCategoryData(mappedData);
      setSelectedCategoryNiche("All");
      setLoading(false);
    });

    return () => {
      unsubscribe();
    };
  };

  const getListings = async () => {
    setLoading(true);
    let arr = [];

    const q = query(
      collection(db, "listings"),
      orderBy("createdAt", "desc"),
      where("projectId", "==", value?.webConfig?.projectsId),
      limit(4)
      // startAfter(startAfterData)
    );

    try {
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        arr.push({ id: doc.id, ...doc.data() });
      });
      setDatas(arr);
    } catch (error) {
      console.log("error getting next listings", error.message);
    } finally {
      setLoading(false);
    }
  };

  const fetchNext = async () => {
    const startAfterData = datas[datas?.length - 1].createdAt;
    // const conditions = [
    // 	{ field: "projectId", operator: "==", value: value?.webConfig?.projectsId }
    // ];
    // const sortBy = { field: "createdAt", direction: "desc" };
    // const limitValue = 4;
    // try {
    // 	// const res = await getCollectionWhereFirebase('listings', 'projectId', '==', value?.webConfig?.projectsId)
    // 	const res = await getCollectionFirebaseV2('listings', conditions, sortBy, limitValue, startAfterData)
    // 	console.log(res, "res")
    // 	setDatas([...datas, ...res])
    // } catch (error) {
    // 	console.log("error getting listings", error.message)
    // }
    let arr = [];

    const q = query(
      collection(db, "listings"),
      orderBy("createdAt", "desc"),
      where("projectId", "==", value?.webConfig?.projectsId),
      limit(4),
      startAfter(startAfterData)
    );

    try {
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        arr.push({ id: doc.id, ...doc.data() });
      });
      setDatas([...datas, ...arr]);
    } catch (error) {
      console.log("error getting next listings", error.message);
    }
  };

  const handleSearchListing = (q) => {
    if (q?.length >= 3) {
      let arr = [];
      setIsSearching(true);
      setLoading(true);
      const searchParameters = {
        q: q,
        query_by: "title",
        filter_by: `projectId:${value?.webConfig?.projectsId}`,
        sort_by: "_text_match:desc",
      };

      clientTypesense
        .collections("listings")
        .documents()
        .search(searchParameters)
        .then((x) => {
          // const hits = x.hits.map((x) => x.document)
          // setDatas(q.length > 0 ? hits : []);
          // setLoading(false);
          const hits = x.hits.map((x) => x.document);
          const searchResults = q.length > 0 ? hits : [];
          for (let i = 0; i < searchResults.length; i++) {
            getSingleDocumentFirebase("listings", searchResults[i]?.id)
              .then((result) => {
                arr.push(result);
              })
              .catch((error) => {
                console.log("error search", error.message);
              });
          }
        })
        .catch((err) => console.log(err.message));
      setTimeout(() => {
        if (arr?.length > 0) {
          setDatas(arr);
          setLoading(false);
        }
      }, 1000);
    } else if (q.length === 0) {
      setIsSearching(false);
      getListings();
    }
  };

  const getDataCategory = async () => {
    try {
      const unsubscribe = await onSnapshot(
        doc(db, "categories", value?.webConfig?.projectsId),
        async (docCat) => {
          if (docCat.data()) {
            const result = await getSingleDocumentFirebase(
              `categories/${
                value?.webConfig?.projectsId
              }/${param.pathname.replace("/", "")}`,
              "data"
            );
            setCategoryList(result);
            setSelectedCategory(param.pathname.replace("/", ""));
          }
        }
      );
      return () => {
        unsubscribe();
      };
    } catch (error) {
      console.log(error, "ini error");
    }
  };

  const handleCategoryFilter = async (values) => {
    const params = values.toLowerCase();
    setSelectedCategoryNiche(params);
    try {
      const q = query(
        collection(db, "listings"),
        where(`category`, "array-contains", params),
        where("projectId", "==", value?.webConfig?.projectsId)
      );
      const unsubscribe = onSnapshot(q, (snapshot) => {
        const data = [];
        snapshot.forEach((doc) => {
          const docData = doc.data();
          data.push({ id: doc.id, ...docData });
        });

        const mappedData = {};
        data.forEach((listing) => {
          const categories = listing.category;
          categories.forEach((category) => {
            if (!mappedData[category]) {
              mappedData[category] = [];
            }
            mappedData[category].push(listing);
          });
        });

        setCategoryData(mappedData);
      });
      return () => {
        unsubscribe();
      };
    } catch (error) {
      console.log(error, "ini error");
    }
  };

  // const handleSearch = async () => {
  // 	const params = input.toLowerCase()
  // 	try {
  // 		const q = query(collection(db, 'listings'),
  // 			where('title', '==', params),
  // 			where("projectId", "==", value?.webConfig?.projectsId)
  // 		);

  // 		const unsubscribe = await onSnapshot(q, (snapshot) => {
  // 			const data = [];
  // 			snapshot.forEach((doc) => {
  // 				const docData = doc.data();
  // 				data.push({ id: doc.id, ...docData });
  // 			});

  // 			const mappedData = {};
  // 			data.forEach((listing) => {
  // 				const categories = listing.category;
  // 				categories.forEach((category) => {
  // 					if (!mappedData[category]) {
  // 						mappedData[category] = [];
  // 					}
  // 					mappedData[category].push(listing);
  // 				});
  // 			});

  // 			setQuery1(mappedData)
  // 		});

  // 		const q2 = query(collection(db, 'listings'),
  // 			where('category', 'array-contains', params),
  // 			where("projectId", "==", value?.webConfig?.projectsId)
  // 		)

  // 		const unsubscribe2 = await onSnapshot(q2, (snapshot) => {
  // 			const data = [];
  // 			snapshot.forEach((doc) => {
  // 				const docData = doc.data();
  // 				data.push({ id: doc.id, ...docData });
  // 			});

  // 			const mappedData = {};
  // 			data.forEach((listing) => {
  // 				const categories = listing.category;
  // 				categories.forEach((category) => {
  // 					if (!mappedData[category]) {
  // 						mappedData[category] = [];
  // 					}
  // 					mappedData[category].push(listing);
  // 				});
  // 			});
  // 			// query2 = mappedData
  // 			// query1.push(mappedData)
  // 			setQuery2(mappedData)
  // 		});
  // 		if (query1 !== {} && query2 !== {}) {

  // 			const merged = { ...query1, ...query2 };
  // 			setCategoryData(merged)
  // 		} else if (query1) {
  // 			setCategoryData(query1)
  // 		} else if (query2) {
  // 			setCategoryData(query2)
  // 		}

  // 		return () => {
  // 			unsubscribe();
  // 			unsubscribe2()
  // 		};
  // 	} catch (error) {
  // 		console.log(error, 'ini error');
  // 	}
  // }

  const ComponentBox = (props) => {
    const { listing, index } = props;
    return (
      <Link to={`view/${listing?.id}`} key={index}>
        <Box p="2.5" shadow="base" borderRadius="md" key={index}>
          <Image
            src={listing?.image}
            objectFit={"contain"}
            aspectRatio={16 / 9}
          />
          <Heading my="2" fontSize="md">
            {listing?.title}
          </Heading>
          <Text mb="2" fontSize={"sm"} color="gray.500" noOfLines={3}>
            {listing?.description}
          </Text>
          {/* <Text textAlign='end'>start from</Text> */}

          <Flex justify={"right"} flexWrap={"wrap"}>
            <PriceTag price={listing?.price} />
          </Flex>

          <Flex
            overflowY={"auto"}
            css={{
              "&::-webkit-scrollbar": {
                height: "0rem",
                width: "4px",
              },
              "&::-webkit-scrollbar-track": {
                width: "6px",
                // backgroundColor: 'whitesmoke'
              },
              "&::-webkit-scrollbar-thumb": {
                // background: 'DarkGray',
                height: "2px",
                // borderRadius: '24px',
              },
            }}
          >
            {listing?.category?.map((x, i) => (
              <Badge key={i} fontSize="2xs" mx="1" colorScheme="green">
                {x}
              </Badge>
            ))}
          </Flex>
        </Box>
      </Link>
    );
  };

  useEffect(() => {
    getListing();
    getDataCategory();
    getListings();
    return () => {
      setDatas([]);
      setCategoryList([]);
      setSelectedCategory(null);
      setSelectedCategoryNiche(null);
    };
  }, [value?.webConfig]);

  return (
    <Box>
      <Flex alignItems={"center"} gap="3" p="2">
        <BackButtons />
        <Heading size="md" color={"black"}>
          Listings
        </Heading>
      </Flex>
      <Stack py={2} pt="5">
        {categoryList && (
          <Flex
            overflowY="auto"
            css={{
              "&::-webkit-scrollbar": {
                height: "0.5rem",
                width: "4px",
              },
              "&::-webkit-scrollbar-track": {
                width: "6px",
              },
              "&::-webkit-scrollbar-thumb": {
                height: "2px",
                borderRadius: "24px",
              },
            }}
            gap="2"
            pb="2"
          >
            <Box
              spacing={2}
              borderBottomWidth={3}
              borderColor={value?.webConfig?.brand[0]}
              py={2}
              px={2}
              borderRadius="md"
              shadow="md"
            >
              <Text
                mx="2"
                maxW="150px"
                textOverflow={"ellipsis"}
                whiteSpace={"nowrap"}
                overflow={"hidden"}
                onClick={() => getListing()}
                textTransform="capitalize"
                fontWeight={selectedCategoryNiche === "All" ? 500 : "normal"}
                color={
                  selectedCategoryNiche === "All" ? "blue.500" : "gray.600"
                }
                cursor="pointer"
              >
                All
              </Text>
            </Box>

            {categoryList?.category?.map((x, index) => {
              return (
                <Tooltip label={`${x}`} key={index}>
                  <Box
                    spacing={2}
                    borderBottomWidth={3}
                    borderColor={value?.webConfig?.brand[0]}
                    py={2}
                    px={2}
                    borderRadius="md"
                    shadow="md"
                  >
                    <Text
                      mx="2"
                      maxW="150px"
                      textOverflow={"ellipsis"}
                      whiteSpace={"nowrap"}
                      overflow={"hidden"}
                      onClick={() => handleCategoryFilter(x)}
                      textTransform="capitalize"
                      fontWeight={
                        selectedCategoryNiche === x.toLowerCase()
                          ? 500
                          : "normal"
                      }
                      color={
                        selectedCategoryNiche === x.toLowerCase()
                          ? "blue.500"
                          : "gray.600"
                      }
                      cursor="pointer"
                    >
                      {x}
                    </Text>
                  </Box>
                </Tooltip>
              );
            })}
          </Flex>
        )}
        <HStack m="1">
          <InputGroup size="md">
            <Input
              type="text"
              placeholder="Search Listing"
              onChange={(e) => handleSearchListing(e.target.value)}
            />

            <InputRightElement
              onClick={(e) => handleSearchListing(e)}
              cursor={"pointer"}
            >
              <FiSearch />
            </InputRightElement>
          </InputGroup>
          {/* <Icon as={FiFilter} />
					<Icon as={FiGrid} /> */}
        </HStack>
        <Divider />
      </Stack>
      {loading && (
        <Center my={3}>
          <Spinner color="gray.500" />
        </Center>
      )}
      {datas?.length > 0 ? (
        <>
          <SimpleGrid columns={selectedCategoryNiche === "All" ? 2 : 1} gap="2">
            {selectedCategoryNiche === "All"
              ? datas?.map((listing, index) => (
                  <ComponentBox listing={listing} index={index} />
                ))
              : Object.entries(categoryData).map(
                  ([category, categoryListing]) => {
                    return (
                      selectedCategoryNiche === category && (
                        <SimpleGrid columns={2} key={category} gap="3" py={2}>
                          {categoryListing?.map((listing, index) => (
                            <ComponentBox listing={listing} index={index} />
                          ))}
                        </SimpleGrid>
                      )
                    );
                  }
                )}
          </SimpleGrid>
          {!isSearching && (
            <Center>
              <Button m={2} variant="ghost" size="sm" onClick={fetchNext}>
                Load More
              </Button>
            </Center>
          )}
        </>
      ) : (
        !loading && (
          <Text
            textAlign={"center"}
            fontWeight={"sm"}
            color={"gray.500"}
            mt="5"
          >
            There is no listing
          </Text>
        )
      )}
    </Box>
  );
}

export default ListingMicroSite;
