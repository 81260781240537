import { Container, HStack } from '@chakra-ui/react'
import React from 'react'
import BasicHeaderComponent from '../Components/Header/BasicHeaderComponent'
import BackButtonComponent from '../Components/Buttons/BackButtonComponent'
import { useLocation } from 'react-router-dom'

function MicroHeaderLayout({ children }) {
	const location = useLocation()
	return (
		<Container maxW='md' p='0'>
			<BasicHeaderComponent />
			{!location.pathname.includes('lesson') || !location.pathname.includes('prompt-gpt') &&
				<HStack m='2'>
					<BackButtonComponent />
				</HStack>
			}
			{children}
		</Container>
	)
}

export default MicroHeaderLayout