'use client'
import {
    Flex,
    Box,
    Button,
    VStack,
    FormControl,
    FormLabel,
    Input,
    InputGroup,
    InputLeftElement,
    Textarea,
    Center,
    useToast,
    ScaleFade,
    Stack,
    Text,
} from '@chakra-ui/react'
import {
    MdOutlineEmail,
} from 'react-icons/md'
import { BsPerson } from 'react-icons/bs'
import { useContext, useEffect, useState } from 'react'
import axios from 'axios'
import { auth } from '../../Config/firebase'
import { GlobalContext } from '../../Hooks/Contexts/GlobalContext';
import LoaderComponent from '../Spinners/LoaderComponent';
import { setDocumentFirebase } from '../../Api/firebaseApi';
import { getRandomString } from '../../Utils/RandomString';

export default function RegistrationForms() {
    const toast = useToast();
    const { handleToast } = useContext(GlobalContext);
    const value = useContext(GlobalContext);

    const [submitData, setSubmitData] = useState({
        event: "Indonesia Spicing The World",
        time: "24/07/2023",
        quantity: 1
    });
    const [invalidForm, setInvalidForm] = useState({});
    const [loading, setLoading] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [redirectUrl, setRedirectUrl] = useState('');


    const handleSubmit = async () => {
        let isThatInvalid = {};
        if (!submitData.name) isThatInvalid.name = true;
        if (!submitData.lastName) isThatInvalid.lastName = true;
        if (!submitData.email) isThatInvalid.email = true;
        if (!submitData.phone) isThatInvalid.phone = true;
        if (!submitData.quantity) isThatInvalid.quantity = true;
        setInvalidForm(isThatInvalid);




        if (submitData?.name && submitData?.email && submitData?.phone && submitData?.quantity) {
            const url = "https://asia-southeast2-deoapp-indonesia.cloudfunctions.net/finpayCreatePayment";

            const dataa = {
                is_production: true,
                email: submitData?.email,
                firstName: submitData?.name,
                lastName: submitData?.lastName,
                mobilePhone: '+62' + submitData?.phone.slice(1),
                externalId: getRandomString(),
                amount: parseInt(2000),
                description: "Pendaftaran",
                companyId: value?.webConfig?.id,
                projectId: value?.webConfig?.projectsId,
                userId: auth?.currentUser?.uid,
                module: "lms",
                redirectUrl: "https://bisabikinbrand1.deoapp.site",
            };

            const options = {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "pFa08EJkVRoT7GDiqk1",
                }
            };

            try {
                setLoading(true);
                const res = await axios.post(url, dataa, options);

                if (res?.data?.status) {
                    window.open(res?.data?.data?.redirectUrl);
                    setRedirectUrl(res?.data?.data?.redirectUrl);

                    const updateData = {
                        ...dataa,
                        orders: [
                            {
                                quantity: submitData?.quantity,
                                name: "EVENT INDONESIA SPICING THE WORLD"
                            }
                        ],
                        createdAt: new Date(),
                        module: "lms",
                        paymentStatus: "PENDING",
                        paymentMethod: "FINPAY"
                    };

                    try {
                        await setDocumentFirebase('orders', res?.data?.data?.external_id_ori, updateData, value?.webConfig?.projectsId);
                    } catch (error) {
                        console.log(error.message, "error saving order")
                    }

                    setLoading(false)
                    setIsSubmitted(true)
                };

            } catch (error) {
                handleToast("Error", "", "error");
            } finally {
                setLoading(false)
            }
        } else {
            handleToast("Error", "", "error");
        }

    }


    const LoadingScreen = () => {
        return (
            <ScaleFade initialScale={36} in>
                <Stack alignItems='center' minH='99vh' justifyContent='center' >
                    <LoaderComponent />
                    <Text>Loading, redirecting...</Text>
                </Stack>
            </ScaleFade>
        )
    };

    if (!loading && !isSubmitted) return (
        <Flex w='full'>
            <Center>
                <Box w="md" borderRadius="lg">
                    <Box m={3} color="#0B0E3F">
                        <VStack spacing={5}>
                            <FormControl id="name">
                                <FormLabel>First Name</FormLabel>
                                <InputGroup borderColor="#E0E1E7">
                                    <InputLeftElement pointerEvents="none">
                                        <BsPerson color="gray.800" />
                                    </InputLeftElement>
                                    <Input isInvalid={invalidForm?.name} onChange={e => setSubmitData({
                                        ...submitData,
                                        name: e.target.value
                                    })} placeholder="Nama" type="text" size="md" />
                                </InputGroup>
                            </FormControl>
                            <FormControl id="name">
                                <FormLabel>Last Name</FormLabel>
                                <InputGroup borderColor="#E0E1E7">
                                    <InputLeftElement pointerEvents="none">
                                        <BsPerson color="gray.800" />
                                    </InputLeftElement>
                                    <Input isInvalid={invalidForm?.lastName} onChange={e => setSubmitData({
                                        ...submitData,
                                        lastName: e.target.value
                                    })} placeholder="Last Name" type="text" size="md" />
                                </InputGroup>
                            </FormControl>
                            <FormControl id="name">
                                <FormLabel>Email</FormLabel>
                                <InputGroup borderColor="#E0E1E7">
                                    <InputLeftElement pointerEvents="none">
                                        <MdOutlineEmail color="gray.800" />
                                    </InputLeftElement>
                                    <Input isInvalid={invalidForm?.email} onChange={e => setSubmitData({
                                        ...submitData,
                                        email: e.target.value
                                    })} placeholder="Email" type="text" size="md" />
                                </InputGroup>
                            </FormControl>
                            <FormControl id="phone">
                                <FormLabel>Phone</FormLabel>
                                <InputGroup borderColor="#E0E1E7">
                                    <InputLeftElement pointerEvents="none">
                                        <MdOutlineEmail color="gray.800" />
                                    </InputLeftElement>
                                    <Input isInvalid={invalidForm?.phone} onChange={e => setSubmitData({
                                        ...submitData,
                                        phone: e.target.value
                                    })} placeholder="Example : 08780017XXXX" type="number" size="md" />
                                </InputGroup>
                            </FormControl>
                            <FormControl id="event">
                                <FormLabel>Event</FormLabel>
                                <InputGroup borderColor="#E0E1E7">
                                    <InputLeftElement pointerEvents="none">
                                        <MdOutlineEmail color="gray.800" />
                                    </InputLeftElement>
                                    <Input bg="gray.300" disabled value="Indonesia Spicing The World, Smesco Jakarta" type="text" size="md" />
                                </InputGroup>
                            </FormControl>
                            <FormControl id="time">
                                <FormLabel>Time and Place</FormLabel>
                                <InputGroup borderColor="#E0E1E7">
                                    <InputLeftElement pointerEvents="none">
                                        <MdOutlineEmail color="gray.800" />
                                    </InputLeftElement>
                                    <Input bg="gray.300" disabled value="Lantai 5 Gedung Smesco – Jl. Gatot Subroto Jakarta" type="text" size="md" />
                                </InputGroup>
                            </FormControl>
                            <FormControl id="peserta">
                                <FormLabel>Total Peserta</FormLabel>
                                <InputGroup borderColor="#E0E1E7">
                                    <InputLeftElement pointerEvents="none">
                                        <MdOutlineEmail color="gray.800" />
                                    </InputLeftElement>
                                    <Input isInvalid={invalidForm?.quantity} onChange={e => setSubmitData({
                                        ...submitData,
                                        quantity: e.target.value
                                    })} type="number" value={submitData?.quantity} size="md" />
                                </InputGroup>
                            </FormControl>
                            <FormControl id="name">
                                <FormLabel>Message</FormLabel>
                                <Textarea
                                    borderColor="gray.300"
                                    _hover={{
                                        borderRadius: 'gray.300',
                                    }}
                                    placeholder="message"
                                />
                            </FormControl>
                            <FormControl id="name" float="right">
                                <Center>
                                    <Button
                                        disabled={loading}
                                        isLoading={loading}
                                        loadingText="Submitting...."
                                        onClick={handleSubmit}
                                        p={8}
                                        variant="solid"
                                        bg="#0D74FF"
                                        color="white"
                                        _hover={{}}
                                    >
                                        DAFTAR SEKARANG
                                    </Button>
                                </Center>
                            </FormControl>
                        </VStack>
                    </Box>
                </Box>
            </Center>
        </Flex>
    )
    if (isSubmitted) return (
        <Box h='80vh' w='full' p='5' display='flex' flexDir='column' justifyContent='center' alignContent='center'>
            <Text fontWeight='bold' textAlign='center' mb='5'>
                You should be redirected to Payment Page, if not please click this link below:
            </Text>
            <a href={`${redirectUrl}`} rel='noopener noreferrer' target='_blank' style={{ color: 'blue', textAlign: 'center' }}
                onMouseEnter={(e) => (e.target.style.textDecoration = 'underline')}
                onMouseLeave={(e) => (e.target.style.textDecoration = 'none')}
            >{redirectUrl}</a>
            <Text textAlign={'center'} mt='5'>You can check your payment status here</Text>
        </Box>
    );

    return <LoadingScreen />;
}