import React from 'react';
import { Route, Routes } from 'react-router-dom';
import MicroFullLayout from '../Layouts/MicroFullLayout';
import MicroHeaderLayout from '../Layouts/MicroHeaderLayout';
import CourseMicroSite from '../Pages/Course/CourseMicroSite';
import SingleCourseSite from '../Pages/Course/SingleCourseSite';
import LessonsListSite from '../Pages/Course/LessonsListSite';
import SingleLessonSite from '../Pages/Course/SingleLessonSite';
import PromptListPage from '../Pages/Prompts/PromptListPage';
import PromptFileListPage from '../Pages/Prompts/PromptFileListPage';
import PromptResultFilePage from '../Pages/Prompts/PromptResultFilePage';

function CourseRoutes() {
	return (
		<Routes>
			<Route path="/" element={<MicroFullLayout><CourseMicroSite /></MicroFullLayout>} />
			<Route path="/:courseId" element={<MicroHeaderLayout><SingleCourseSite /></MicroHeaderLayout>} />
			<Route path="/:courseId/section/:sectionTitle" element={<MicroHeaderLayout><LessonsListSite /></MicroHeaderLayout>} />

			<Route path="/:courseId/lesson/:lessonId" element={<MicroHeaderLayout>< SingleLessonSite /></MicroHeaderLayout>} />
			<Route path="/home-prompt-gpt" element={<MicroHeaderLayout><PromptListPage /></MicroHeaderLayout>} />

			<Route path="/:courseId/prompt-gpt/:id_file" element={<MicroHeaderLayout><PromptResultFilePage /></MicroHeaderLayout>} />

		</Routes>
	)
}

export default CourseRoutes