import React from 'react'
import { Route, Routes } from 'react-router-dom'
import MicroFullLayout from '../Layouts/MicroFullLayout'
import MicroHeaderLayout from '../Layouts/MicroHeaderLayout'
import ListingMicroSite from '../Pages/Listing/ListingMicroSite'
import ListingViewMicroSite from '../Pages/Listing/ListingViewMicroSite'

function ListingRoutes() {
  return (
	<Routes>
	<Route path="/" element={<MicroFullLayout><ListingMicroSite /></MicroFullLayout>} />
	<Route path="/view/:id" element={<MicroHeaderLayout>< ListingViewMicroSite /></MicroHeaderLayout>} />
	{/* <Route path="/subscriptions" element={<MicroHeaderLayout>< Subsriptions /></MicroHeaderLayout>} />
	<Route path="/credit-card" element={<MicroFullLayout><CreditCard /></MicroFullLayout>} />
	<Route path="/address" element={<MicroFullLayout><Address /></MicroFullLayout>} />
	<Route path="/contact" element={<MicroFullLayout><Contact /></MicroFullLayout>} /> */}
	{/* <Route path="setting/*" element={<SettingRouter />} /> */}
	{/* <Route path="example/*" element={<ExampleRouter />} /> */}
</Routes>
  )
}

export default ListingRoutes