import { Box, Button, Center, Flex, FormControl, FormLabel, Heading, Image, Input, Select, Stack, Text, Textarea, useToast } from '@chakra-ui/react'
import axios from 'axios'
import React, { useContext, useEffect, useState } from 'react'
import { getRandomString } from '../../Utils/RandomString';
import { addDocumentFirebase } from '../../Api/firebaseApi';
import { GlobalContext } from '../../Hooks/Contexts/GlobalContext';
import { useNavigate } from 'react-router-dom';

const ShipperForm = () => {
	const [provinces, setProvinces] = useState([]);
	const [cities, setCities] = useState([]);
	const [suburbs, setSuburbs] = useState([]);
	const [areas, setAreas] = useState([]);
	const [selectedProvince, setSelectedProvince] = useState(0);
	const [selectedCity, setSelectedCity] = useState(0);
	const [selectedSuburb, setSelectedSuburb] = useState(0);
	const [selectedArea, setSelectedArea] = useState(0);
	const [pricingData, setPricingData] = useState({});
	const [fetchingPrice, setFetchingPrice] = useState(false);
	const [fullAddress, setFullAddress] = useState("");
	const [data, setData] = useState({});
	const [isSubmitting, setIsSubmitting] = useState(false);
	const toast = useToast();
	const { handleToast } = useContext(GlobalContext);


	let origin = {
		area_id: 4747,
		lat: "-6.2922975",
		lng: "106.79719",
		suburb_id: 488
	};

	const baseUrl = "https://asia-southeast2-deoapp-indonesia.cloudfunctions.net/"

	const getProvinces = async () => {
		const config = {
			method: 'post',
			maxBodyLength: Infinity,
			url: baseUrl + "shipperProvinceList",
			headers: {
				"Content-Type": "application/json",
				"Authorization": "pFa08EJkVRoT7GDiqk1",
			},
			data: {
				is_production: true
			}
		};
		try {
			const resProvinces = await axios(config);
			if (resProvinces?.data?.data !== undefined && resProvinces?.data?.data?.length > 0) {
				setProvinces(resProvinces?.data?.data);
			}
		} catch (error) {
			console.log(error, "error.message")
		}
	};

	const getCity = async () => {
		const config = {
			method: 'post',
			maxBodyLength: Infinity,
			url: baseUrl + "shipperCityList",
			headers: {
				"Content-Type": "application/json",
				"Authorization": "pFa08EJkVRoT7GDiqk1",
			},
			data: {
				is_production: true,
				province_id: JSON.parse(selectedProvince).id
			}
		};
		try {
			const resCities = await axios(config);
			if (resCities?.data?.data !== undefined && resCities?.data?.data?.length > 0) {
				setCities(resCities?.data?.data);
			}
		} catch (error) {
			console.log(error, "error.message")
		}
	};

	const getSuburb = async () => {
		const config = {
			method: 'post',
			maxBodyLength: Infinity,
			url: baseUrl + "shipperSuburbList",
			headers: {
				"Content-Type": "application/json",
				"Authorization": "pFa08EJkVRoT7GDiqk1",
			},
			data: {
				is_production: true,
				city_id: JSON.parse(selectedCity)?.id
			}
		};
		try {
			const resSuburbs = await axios(config);
			if (resSuburbs?.data?.data !== undefined && resSuburbs?.data?.data?.length > 0) {
				setSuburbs(resSuburbs?.data?.data);
			}
		} catch (error) {
			console.log(error, "error.message")
		}
	};

	const getArea = async () => {
		const config = {
			method: 'post',
			maxBodyLength: Infinity,
			url: baseUrl + "shipperAreaList",
			headers: {
				"Content-Type": "application/json",
				"Authorization": "pFa08EJkVRoT7GDiqk1",
			},
			data: {
				is_production: true,
				suburb_id: JSON.parse(selectedSuburb)?.id
			}
		};
		try {
			const resAreas = await axios(config);
			if (resAreas?.data?.data !== undefined && resAreas?.data?.data?.length > 0) {
				setAreas(resAreas?.data?.data);
			}
		} catch (error) {
			console.log(error, "error.message")
		}
	};

	const getPricing = async () => {
		setFetchingPrice(true)

		const parsed = JSON.parse(selectedArea);
		const destination = {
			area_id: parsed?.id,
			lat: String(parsed?.lat),
			lng: String(parsed?.lng),
			suburb_id: parseInt(selectedSuburb)
		};
		const payload = {
			is_production: true,
			cod: false,
			destination: destination,
			for_order: true,
			item_value: 40000,
			weight: 1,
			limit: 30,
			width: 20,
			length: 22,
			height: 5,
			origin: origin,
			page: 1,
			sort_by: [
				"final_price"
			],
		};

		const config = {
			method: 'post',
			maxBodyLength: Infinity,
			url: baseUrl + "shipperPricingList",
			headers: {
				"Content-Type": "application/json",
				"Authorization": "pFa08EJkVRoT7GDiqk1",
			},
			data: payload
		};

		// console.log(config, "config")

		try {
			const resPricing = await axios(config);
			if (resPricing !== undefined) {
				setPricingData(resPricing?.data?.data);
			};
			// console.log(example)
		} catch (error) {
			console.log(error, 'error')
		} finally {
			setFetchingPrice(false);
		}
	};

	const order = async (args) => {
		const parsed = selectedArea && JSON.parse(selectedArea);
		const destination = {
			area_id: parsed?.id,
			lat: String(parsed?.lat),
			lng: String(parsed?.lng),
		};
		delete origin.suburb_id;

		const payload = {
			consignee: {
				name: "Reinhart",
				phone_number: "6287806848932"
			},
			consigner: {
				name: "Kasim",
				phone_number: "6285123138095"
			},
			courier: {
				cod: false,
				rate_id: args.id,
				use_insurance: true
			},
			coverage: "domestic",
			destination: {
				address: 'Jl. Lumbu Tengah VI E no.75A, Rawalumbu, Bekasi',
				...destination
			},
			external_id: getRandomString(),
			origin: {
				address: 'Jl. RS. Fatmawati Raya No.30 H, RT.1/RW.10, Cilandak Bar., Kec. Cilandak, Kota Jakarta Selatan, Daerah Khusus Ibukota Jakarta 12430',
				...origin
			},
			package: {
				width: 16,
				length: 22,
				height: 5,
				items: [{
					name: "Buku Bisa Bikin Brand 1-Subiakto Priosoedarsono",
					price: 555000,
					qty: 1
				}],
				package_type: 2,
				price: 555000,
				weight: 1
			},
			payment_type: "postpay"
		}

		// console.log(payload, "payload")
		const config = {
			method: 'post',
			maxBodyLength: Infinity,
			url: baseUrl + "shipperCreateOrder",
			headers: {
				"Content-Type": "application/json",
				"Authorization": "pFa08EJkVRoT7GDiqk1",
			},
			data: payload
		};
		try {
			const resOrder = await axios(config);
			// const resOrder = await axios.post(url, payload);
			console.log(resOrder, "resOrder");
		} catch (error) {
			console.log(error, "error")
		}
	};

	const handleSubmit = async () => {
		setIsSubmitting(true);


		const uploadData = {
			province : JSON.parse(selectedProvince)?.name,
			city : JSON.parse(selectedCity)?.name,
			suburb : JSON.parse(selectedSuburb)?.name,
			area : JSON.parse(selectedArea)?.name,
			postcode : JSON.parse(selectedArea)?.postcode,
			fullAddress : fullAddress
		};

		try {
			const id = await addDocumentFirebase('addresses', { ...data, ...uploadData }, "Z55P0SgE1tztgKziGsmE")
			if (id) {
				handleToast('Address Submitted','Thankyou for submitting','success')
				handleToast('redirecting...','Thankyou for submitting','warning')
			};
			// navigate("/bisabikinbrand1.deoapp.site");
		} catch (error) {
			handleToast('Error',error.message,'error')
		} finally {
			setIsSubmitting(false)
		};
	}

	useEffect(() => {
		getProvinces()
	}, []);

	useEffect(() => {
		getCity()
	}, [selectedProvince]);

	useEffect(() => {
		getSuburb()
	}, [selectedCity]);

	useEffect(() => {
		getArea()
	}, [selectedSuburb]);

	useEffect(() => {
		getPricing();
	}, [selectedArea]);



	return (
		<Box p={2} maxW="md">
			<Stack mt={5} mx={2} gap={1}>
				<FormControl>
					<FormLabel>Full Name</FormLabel>
					<Input type='text' onChange={e => setData({ ...data, name: e.target.value })} />
				</FormControl>
				<FormControl>
					<FormLabel>Email</FormLabel>
					<Input type='email' onChange={e => setData({ ...data, email: e.target.value })} />
				</FormControl>
				<FormControl>
					<FormLabel>Phone</FormLabel>
					<Input type='number' onChange={e => setData({ ...data, phone: e.target.value })} />
				</FormControl>
			</Stack>




			<Stack mt={5} mx={2} gap={1}>
				<Heading size='md'>Pilih Pengiriman</Heading>
				<Text fontSize={10}>Mohon mengisi alamat dengan benar.</Text>
				<Select placeholder='Pilih Provinsi' onChange={e => setSelectedProvince(e.target.value)}>
					{provinces?.sort((a, b) => a.name?.localeCompare(b?.name))?.map((province, i) => (
						<option key={i} value={JSON.stringify(province)}>{province?.name}</option>
					))}
				</Select>
				<Select placeholder='Pilih Kota' onChange={e => setSelectedCity(e.target.value)}>
					{cities?.sort((a, b) => a.name?.localeCompare(b?.name))?.map((city, i) => (
						<option key={i} value={JSON.stringify(city)}>{city?.name}</option>
					))}
				</Select>
				<Select placeholder='Pilih Kecamatan' onChange={e => setSelectedSuburb(e.target.value)}>
					{suburbs?.sort((a, b) => a.name?.localeCompare(b?.name))?.map((suburb, i) => (
						<option key={i} value={JSON.stringify(suburb)}>{suburb?.name}</option>
					))}
				</Select>
				<Select placeholder='Pilih Kelurahan' onChange={e => setSelectedArea(e.target.value)}>
					{areas?.sort((a, b) => a.name?.localeCompare(b?.name))?.map((area, i) => (
						<option
							key={i}
							value={JSON.stringify({
								id: area.id,
								lat: area.lat,
								lng: area.lng,
								name: area.name,
								postcode: area.postcode,
							})}
						>{area?.name}</option>
					))}
				</Select>

				<Box my={2}>
					<Text>Alamat Lengkap: </Text>
					<Textarea onChange={e => setFullAddress(e.target.value)} placeholder='Jl. Lumbu Tengah VI E, Bekasi' isRequired />
				</Box>


			</Stack>
			{/* {pricingData !== undefined && pricingData !== {} && pricingData?.pricings?.length > 0 ?
				<Stack mt={5} mx={2} gap={1}>
					{pricingData?.pricings?.map((x, i) => (
						<Flex
							cursor="pointer"
							_hover={{
								bg: "gray.200"
							}}
							_active={{
								bg: "gray.300"
							}}
							bg={selectedCourier?.rate?.id === x?.rate?.id ? "gray.100" : "white"}
							justifyContent='space-between'
							borderBottomWidth={1}
							gap={2}
							key={i}
							p={1}
							onClick={() => {
								setRate(x?.rate)
								setSelectedCourier(x)
								console.log({ ...x, ...data })
								// order(x?.rate)
							}}
						>
							<Stack>
								<Image src={x?.logistic?.logo_url} w={20} />
								<Text fontWeight="bold" fontSize={10}>{x?.logistic?.code}-{x?.rate?.name}</Text>
							</Stack>
							<Stack alignItems='flex-end'>
								<Text fontSize={10} color='gray.700'>IDR <strong>{new Intl.NumberFormat().format(x.final_price)}</strong></Text>
								<Text
									fontSize={10}
									color='gray.700'
								>
									Estimasi {x?.min_day === x?.max_day ? x?.min_day + " " : x?.min_day + "-" + x?.max_day + " "}
									hari tiba
								</Text>
							</Stack>
						</Flex>
					))}
				</Stack>
				: <></>} */}
			<Button
				colorScheme='facebook'
				w='full'
				size='sm'
				onClick={handleSubmit}
				isLoading={isSubmitting}
				disabled={isSubmitting}
			>
				Submit
			</Button>
		</Box>

	)
}

export default ShipperForm