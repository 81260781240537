import {
     Button,
     FormControl,
     Flex,
     Heading,
     Input,
     Stack,
     Text,
     useColorModeValue,
     VStack,
} from '@chakra-ui/react';
import { sendPasswordResetEmail } from 'firebase/auth';
import { auth } from '../../Config/firebase';
import { useContext, useState } from 'react';
import { GlobalContext } from '../../Hooks/Contexts/GlobalContext';

export default function ForgotPasswordPage() {
     const [email, setEmail] = useState(null)
     const value = useContext(GlobalContext);

     const handleResetPassword = () => {
          sendPasswordResetEmail(auth, email)
               .then(() => {
                    value.handleToast('Verification send', 'Verification has been sent to your email account', 'success')
               })
               .catch((error) => {
                    value.handleToast(`${error.code}`, `${error.message}`, 'error')
               });
     }

     return (
          <Flex
               minH={'80vh'}
               align={'center'}
               justify={'center'}
               bg={useColorModeValue('white', 'gray.700')}
               py={{
                    base: '12',
                    md: '24',
               }}
               px={{
                    base: '0',
                    sm: '8',
               }}
          >
               <VStack w={'full'}>
                    <Heading lineHeight={1.1} size='md'>
                         Forgot your password?
                    </Heading>
                    <Stack
                         spacing={4}
                         w={'full'}
                         maxW={'md'}
                         bg={useColorModeValue('white', 'gray.700')}
                         rounded={'xl'}
                         boxShadow={'lg'}
                         p={6}
                         my={12}>

                         <Text
                              fontSize={{ base: 'sm', sm: 'md' }}
                              color={useColorModeValue('gray.800', 'gray.400')}>
                              You&apos;ll get an email with a reset link
                         </Text>
                         <FormControl id="email">
                              <Input
                                   placeholder="your-email@example.com"
                                   _placeholder={{ color: 'gray.500' }}
                                   type="email"
                                   onChange={(e) => setEmail(e.target.value)}
                              />
                         </FormControl>
                         <Stack spacing={6}>
                              <Button
                                   bg={'blue.400'}
                                   color={'white'}
                                   _hover={{
                                        bg: 'blue.500',
                                   }}
                                   onClick={() => handleResetPassword()}
                              >
                                   Request Reset
                              </Button>
                         </Stack>
                    </Stack>
               </VStack>
          </Flex>
     );
}