import React, { useEffect, useState } from 'react'
import { Box, Heading, Text, Stack, SimpleGrid, Spinner, Center } from '@chakra-ui/react'
import ViewBars from '../../Components/ViewBars/ViewBars'
import { useParams } from 'react-router-dom'
import { getCollectionFirebase } from '../../Api/firebaseApi'
import SectionCardComponent from '../../Components/Cards/SectionCardComponent'

const LessonsListSite = () => {
     const [layout, setLayout] = useState("single");
     const [data, setData] = useState(null);
     const params = useParams();


     const getLessons = async () => {
          // const res = await getCollectionWhereFirebase(`courses/${params.courseId}/lessons`, 'section', '==', state.data.section)
          const res = await getCollectionFirebase(`courses/${params.courseId}/lessons`, { conditions: [] }, { sortBy: null }, { limitValue: null });
          if (res) {
               setData(res.filter(item => item.section === params.sectionTitle));
          };
     };

     useEffect(() => {
          getLessons();
     }, []);


     return (
          <Box>
               <SimpleGrid columns="3" bgColor="brand.1" p="2">
                    <Text>Filter</Text>
                    <Heading textAlign="center" fontSize="md">
                         Lessons
                    </Heading>
                    <Stack alignItems="flex-end" justifyContent="center">
                         <ViewBars setLayout={setLayout} layout={layout} />
                    </Stack>
               </SimpleGrid>
               {!data ? (
                    <Center>
                         <Spinner />
                    </Center>
               ) : (

                    <SimpleGrid columns={layout === "single" ? 1 : 2}>
                         {data?.length > 0 ? data?.map((x, i) => {
                              if (layout === "single")
                                   return (
                                        <SectionCardComponent key={i} data={x} type='lesson' />
                                   );
                              else
                                   return (
                                        <SectionCardComponent key={i} data={x} type='lesson' />
                                   );
                         }) : null}
                    </SimpleGrid>
               )}
          </Box>

     )
}

export default LessonsListSite