import {
	Box,
	Button,
	Checkbox,
	Container,
	FormControl,
	FormLabel,
	Heading,
	HStack,
	Icon,
	Input,
	InputGroup,
	InputLeftElement,
	InputRightElement,
	Stack,
	Text,
} from '@chakra-ui/react'
import { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { addArrayFirebase, loginUser } from '../../Api/firebaseApi'
import { auth } from '../../Config/firebase'
import { GlobalContext } from '../../Hooks/Contexts/GlobalContext'
import { browserSessionPersistence, setPersistence } from 'firebase/auth';
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";

function LoginPage() {
	const [data, setData] = useState({});
	const [loading, setLoading] = useState(false);
	const [rememberMe, setRememberMe] = useState(false);
	const [showPassword, setShowPassword] = useState(false);
	const value = useContext(GlobalContext);
	const pendingLocation = localStorage.getItem('pendingLocation');


	const navigate = useNavigate();

	const handleLogin = async () => {
		setLoading(true);
		try {
			const result = await loginUser(data.email, data.password)
			if (rememberMe) {
				// Set persistence to 'LOCAL' if "Remember Me" is checked
				await setPersistence(auth, browserSessionPersistence)
			}

			if (result.status === 'success') {
				value.handleToast('Login Sukses', 'Anda telah berhasil login', 'success')
				await addArrayFirebase('users', auth.currentUser.uid, 'users', value.webConfig.projectsId)
				setLoading(false)

				if (!pendingLocation) return navigate('/');
				// if (pendingCourse) return navigate('//'+pendingCourse);


				window.location.href = (JSON.parse(pendingLocation)
					// ?.replace("digitalmarketing.localhost:3000", "")
					// ?.replace("digitalmarketing.deoapp.site", "")
					// ?.replace("http://digitalmarketing.deoapp.site", "")
					// ?.replace("https://digitalmarketing.deoapp.site", "")
				)
			}
			else {
				value.handleToast(`${result.status}`, `${result.data}`, 'error')
				setLoading(false)
			}
		} catch (error) {
			console.log(error.message)
			value.handleToast(`${error.code}`, `${error.message}`, 'error')
		} finally {
			localStorage.removeItem("pendingLocation")
		};
	};

	const loginChecker = async () => {
		if (value.auth) {
			console.log(auth.currentUser.uid, 'login checker')
			navigate('/')
		}
	};

	const onEnter = (e) => {
		if (e.key === "Enter") handleLogin()
	};


	useEffect(() => {
		loginChecker()
		return () => {
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])


	return (
		<Container
			h='99vh'
			maxW="lg"
			py={{
				base: '12',
				md: '24',
			}}
			px={{
				base: '0',
				sm: '8',
			}}
		>
			<Stack spacing="8">
				<Stack spacing="6">
					<Stack
						spacing={{
							base: '2',
							md: '3',
						}}
						textAlign="center"
					>
						<Heading
							size={{
								base: 'xs',
								md: 'sm',
							}}
						>
							Log in to your account
						</Heading>
						<HStack spacing="1" justify="center">
							<Text color="muted">Don't have an account?</Text>
							<Button variant="link" colorScheme={value.webConfig.colorScheme} onClick={() => navigate('/authentication/signup')}>
								Sign up
							</Button>
						</HStack>
					</Stack>
				</Stack>
				<Box
					py={{
						base: '0',
						sm: '8',
					}}
					px={{
						base: '4',
						sm: '10',
					}}
					bg={{
						base: 'transparent',
						sm: 'bg-surface',
					}}
					boxShadow={{
						base: 'none',
						sm: 'md',
					}}
					borderRadius={{
						base: 'none',
						sm: 'xl',
					}}
				>
					<Stack spacing="6">
						<Stack spacing="5">
							<FormControl>
								<FormLabel htmlFor="email">Email</FormLabel>
								<Input id="email" type="email" onChange={(e) => setData({ ...data, email: e.target.value })} />
							</FormControl>
							<FormControl>
								<FormLabel htmlFor="password">Password</FormLabel>
								<InputGroup>
									<Input
										onKeyDown={onEnter}
										id="password"
										type={showPassword ? "text" : "password"}
										onChange={(e) => setData({ ...data, password: e.target.value })}
									/>
									<InputRightElement
										cursor="pointer"
										onClick={() => setShowPassword(!showPassword)}
									>
										<Icon
											as={showPassword ? AiOutlineEye : AiOutlineEyeInvisible}
											color="gray.500"
										/>
									</InputRightElement>
								</InputGroup>
							</FormControl>
						</Stack>
						<HStack justify="space-between">
							<Checkbox onChange={(e) => setRememberMe(e.target.checked)}>Remember me</Checkbox>
							<Button variant="link" colorScheme="blue" size="sm" onClick={() => navigate('/authentication/forgot-password')}>
								Forgot password?
							</Button>
						</HStack>
						<Stack spacing="6">
							{loading ?
								<Button isLoading variant="primary">Sign in</Button>

								:
								<Button variant="primary"
									onClick={() => handleLogin()}
								>Sign in</Button>
							}
							{/* <HStack>
								<Divider />
								<Text fontSize="sm" whiteSpace="nowrap" color="muted">
									or continue with
								</Text>
								<Divider />
							</HStack> */}
							{/* <OAuthButtonGroup /> */}
						</Stack>
					</Stack>
				</Box>
			</Stack>
		</Container>
	)
}






export default LoginPage