import React, { useContext } from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { getSingleDocumentFirebase } from '../../Api/firebaseApi'
import { useParams } from 'react-router-dom'
import { Box, Button, Container, FormControl, Heading, Input, Select, SimpleGrid, Stack, Text, Textarea, useToast } from '@chakra-ui/react'
import axios from 'axios'
import { finpayCreatePayment } from '../../Api/finpayPaymentApi'
import { getRandomString } from '../../Utils/RandomString'
import TicketCard from '../../Components/Cards/TicketCard'
import PaymentDetail from '../../Components/Payment/PaymentDetail'

const PaymentPage = () => {
    const params = useParams()
    const [formFields, setFormFields] = useState();
    const [formValues, setFormValues] = useState({});
    const [formDetails, setFormDetails] = useState({});
    const [ticketDetails, setTicketDetails] = useState({});
    const toast = useToast();
    const [loading, setLoading] = useState(false);

    const [idLeads, setIdLeads] = useState("")
    const [dataLeads, setDataLeads] = useState("")

    const getData = async () => {
        getSingleDocumentFirebase('tickets', params.id).then((ticket) => {
            setTicketDetails(ticket);
            getSingleDocumentFirebase('forms', ticket?.formId).then((form) => {
                setFormDetails(form);
                console.log(form, 'form');
                setFormFields(form?.form_fields)
            })
        }).catch((err) => console.log(err , 'ini error'))
    }

    const getDataLeads = (id) => {
        getSingleDocumentFirebase('leads', id).then((data) => {
            setDataLeads(data);
            console.log(data, 'xxx');
        })
    }

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormValues((prevValues) => ({ ...prevValues, [name]: value }));
    };


    const renderFormFields = (opportunityValue) => {
        if (formFields?.length > 0) {
            return formFields?.map((field) => {
                const { label, type, name, placeholder, isRequired, options, formId } = field;
                const inputPlaceholder = placeholder || '';
                const inputIsRequired = isRequired || false;
                const inputProps = { name, onChange: handleInputChange, value: formValues[name] || '' };

                const handleSubmit = async () => {
                    setLoading(true)
                    let updateData = formValues
                    updateData.formId = formId
                    updateData.opportunity_value = opportunityValue ? opportunityValue : "0"


                    const formFields = updateData



                    try {
                        const resultSubmitLeads = await axios.post('https://asia-southeast2-deoapp-indonesia.cloudfunctions.net/createLead', formFields)
                        console.log(resultSubmitLeads, 'ini ress')
                        if (resultSubmitLeads?.data?.status === true) {
                            setIdLeads(resultSubmitLeads?.data?.message?.leadId)
                            getDataLeads(resultSubmitLeads?.data?.message?.leadId)

                            if (formDetails.enableFacebookPixel) {
                                const fbPixelId = formDetails.facebookPixelId; // Ambil Facebook Pixel ID dari formDetails
                                // Ganti dengan kode Anda untuk mengirim data ke Facebook Pixel
                                // Misalnya, Anda dapat menggunakan kode Facebook Pixel berikut ini sebagai contoh:
                                // facebookPixel('track', 'Lead', { content_name: 'Lead Conversion', content_category: 'Form Submission' });
                                // Pastikan Anda memiliki kode Facebook Pixel yang sesuai di tempat yang sesuai.
                                console.log(fbPixelId, 'xxx')

                                // fbq('track', 'Lead', {
                                //     content_name: 'Lead Conversion', // Nama konten yang dikonversi (Anda dapat menyesuaikannya)
                                //     content_category: 'Form Submission', // Kategori konten (Anda dapat menyesuaikannya)
                                // });

                                // Contoh:
                                // fbq('track', 'Lead', { content_name: 'Lead Conversion', content_category: 'Form Submission' });
                            }

                        }

                    } catch (error) {
                        console.log(error, 'ini error')
                    }
                    finally {
                        setLoading(false)

                    }
                };


                return (
                    <FormControl key={name} isRequired={inputIsRequired}>
                        {type !== "button" && (
                            <Text>{label}{inputIsRequired ? <span style={{ color: 'red' }}>*</span> : null}</Text>
                        )}
                        {type === 'text' && <Input my={3} type="text" placeholder={inputPlaceholder} {...inputProps} />}
                        {type === 'number' && <Input my={3} type="number" placeholder={inputPlaceholder} {...inputProps} />}
                        {type === 'email' && <Input my={3} type="email" placeholder={inputPlaceholder} {...inputProps} />}
                        {type === 'textarea' && <Textarea name={name} placeholder={inputPlaceholder} {...inputProps} />}
                        {type === 'select' && (
                            <Select name={name} placeholder={inputPlaceholder} {...inputProps}>
                                {options?.map((option, index) => (
                                    <option key={index} value={option}>
                                        {option}
                                    </option>
                                ))}
                            </Select>
                        )}
                        {type === 'date' && <Input my={3} type="date" placeholder={inputPlaceholder} {...inputProps} />}
                        {type === 'time' && <Input my={3} type="time" placeholder={inputPlaceholder} {...inputProps} />}
                        {type === 'file' && <Input my={3} type="file" placeholder={inputPlaceholder} {...inputProps} />}
                        {type === 'button' && <Button my={5} w="100%"
                            isLoading={loading} isDisabled={loading} loadingText="Submitting data..."
                            onClick={handleSubmit} colorScheme="blue">{label}</Button>}
                    </FormControl>
                );
            });
        }

    };

    useEffect(() => {
        getData()
    }, [])

    return (
        <>
            <Box p={2} h="100vh">
                <Stack >
                    <SimpleGrid columns={[1, null, 2]} gap={3} >
                        {ticketDetails && (
                            <Stack bgColor={'white'} p={[1, 1, 5]} spacing={5} borderRadius='md' shadow={'md'}>
                                <Heading size={'md'}>Product Active</Heading>
                                <Stack onClick={() => console.log(ticketDetails, 'ini xx')}>
                                    <TicketCard item={ticketDetails} />
                                </Stack>
                            </Stack>
                        )}
                        <Stack p={[1, 1, 5]} bgColor={'white'} minH={'530px'} spacing={5} borderRadius='md' shadow={'md'}>
                            {dataLeads === "" ? (
                                <>
                                    <Stack>
                                        <Heading size={'md'}>Recipient data: </Heading>
                                    </Stack>
                                    <Stack spacing={3} p={[1, 1, 5]}>
                                        {renderFormFields(ticketDetails?.price, setLoading)}
                                    </Stack>
                                </>
                            ) : (
                                <>
                                    <Stack>
                                        {formDetails.paymentMethod === "xendit" ? (
                                            <PaymentDetail dataLeads={dataLeads} dataTicket={ticketDetails} />

                                        ) : (
                                            <Stack>
                                                <Heading size={'md'}>We dont have any method payment</Heading>
                                            </Stack>
                                        )}
                                    </Stack>
                                </>
                            )}

                        </Stack>
                    </SimpleGrid>
                </Stack>
            </Box>
        </>
    )
}

export default PaymentPage