import { Gallery } from './Gallery'

export const CarouselComponent = (props) => {
	let images = []
	if (!props.images)
		images = [
			{
				id: '01',
				image: 'https://www.pngmart.com/files/22/Gray-Background-PNG-Isolated-File.png',
				alt: ' ',
			},
			{
				id: '02',
				image: 'https://www.pngmart.com/files/22/Gray-Background-PNG-Isolated-File.png',
				alt: ' ',
			},
			{
				id: '03',
				image: 'https://www.pngmart.com/files/22/Gray-Background-PNG-Isolated-File.png',
				alt: ' ',
			},

		]
	else
		images = props.images

	return (
		<Gallery images={images} aspectRatio={props.aspectRatio} />
	)
}