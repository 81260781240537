import {
  Box,
  Button,
  Center,
  Flex,
  HStack,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  SimpleGrid,
  Spacer,
  Stack,
  Text,
  Tooltip,
  useColorModeValue,
} from "@chakra-ui/react";
import React, { useContext, useEffect, useRef, useState } from "react";
import ListCardComponent from "../../Components/Cards/ListCardComponent";
// import ViewBars from "../../Components/ViewBars/ViewBars";
import { GlobalContext } from "../../Hooks/Contexts/GlobalContext";
import { AiOutlineSearch } from "react-icons/ai";
import { MdClear } from "react-icons/md";
// import { clientTypesense } from "../../Api/Typesense";
import ViewBars from "../../Components/ViewBars/ViewBars";
import {
  collection,
  query,
  orderBy,
  startAfter,
  limit,
  getDocs,
  where,
  onSnapshot,
  doc,
} from "firebase/firestore";
import { db } from "../../Config/firebase";
import { clientTypesense } from "../../Api/Typesense";
import { getSingleDocumentFirebase } from "../../Api/firebaseApi";
import { useLocation } from "react-router-dom";
import BackButtons from "../../Components/Buttons/BackButtonComponent";

function CourseMicroSite() {
  const [layout, setLayout] = useState("single");
  const [searchResults, setSearchResults] = useState([]);
  const [searchString, setSearchString] = useState("");
  const [categoryData, setCategoryData] = useState([]);
  const [courses, setCourses] = useState([]);
  const [isSearchActive, setIsSearchActive] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedCategoryNiche, setSelectedCategoryNiche] = useState("All");
  const [isFilteringCategory, setIsFilteringCategory] = useState(false);
  const value = useContext(GlobalContext);
  const [categoryList, setCategoryList] = useState(["beauty", "food"]);
  // const courses = value?.courses;
  const bgCol = useColorModeValue("white", "gray.800");
  const fontCol = useColorModeValue("blackAlpha.700", "whiteAlpha.700");
  const param = useLocation();

  const observerTarget = useRef(null);

  const getDataCategory = async () => {
    try {
      const unsubscribe = await onSnapshot(
        doc(db, "categories", value?.webConfig?.projectsId),
        async (docCat) => {
          if (docCat.data()) {
            const result = await getSingleDocumentFirebase(
              `categories/${value?.webConfig?.projectsId
              }/${param.pathname.replace("/", "")}`,
              "data"
            );
            setCategoryList(result);
            setSelectedCategory(param.pathname.replace("/", ""));
          }
        }
      );
      return () => {
        unsubscribe();
      };
    } catch (error) {
      console.log(error, "ini error");
    }
  };

  const handleCategoryFilter = async (values) => {
    setIsFilteringCategory(true);
    const params = values.toLowerCase();
    setSelectedCategoryNiche(params);
    try {
      const q = query(
        collection(db, "courses"),
        where(`category`, "array-contains", params),
        where("projectsId", "==", value?.webConfig?.projectsId)
      );
      const unsubscribe = onSnapshot(q, (snapshot) => {
        const data = [];
        snapshot.forEach((doc) => {
          const docData = doc.data();
          data.push({ id: doc.id, ...docData });
        });

        setCourses(data);
      });
      return () => {
        unsubscribe();
      };
    } catch (error) {
      console.log(error, "ini error");
    }
  };

  const removeFilterCategory = () => {
    setIsFilteringCategory(false);
    setIsSearchActive(false);
    getCourses();
  };

  const handleSeachCourses = () => {
    const found = courses.filter((course) => {
      return (
        JSON.stringify(course)
          ?.toLowerCase()
          ?.indexOf(searchString?.toLowerCase()) !== -1
      );
    });
    setSearchResults(found);
  };

  const handleSearchCourse = async (q) => {
    setSearchString(q);
    if (q?.length >= 3) {
      let arr = [];
      // setLoading(true);
      const searchParameters = {
        q: q,
        query_by: "title",
        filter_by: `projectsId:${value?.webConfig?.projectsId}`,
        sort_by: "_text_match:asc",
      };
      clientTypesense
        .collections("courses")
        .documents()
        .search(searchParameters)
        .then((x) => {
          const hits = x.hits.map((x) => x.document);
          // const searchResults = q.length > 0 ? hits : [];

          hits.forEach((x) => {
            getSingleDocumentFirebase("courses", x?.id)
              .then((result) => {
                arr.push(result);
                // console.log("this is the result", result)
              })
              .catch((error) => {
                console.log("error search", error.message);
              });
          });
        })
        .catch((err) => console.log(err.message));

      setTimeout(() => {
        if (arr?.length > 0) {
          setIsSearchActive(true);
          setSearchResults(arr);
        }
      }, 800);
    }
  };

  const getCourses = async () => {
    let arr = [];
    try {
      const next = query(
        collection(db, "courses"),
        orderBy("createdAt"),
        where("projectsId", "==", value?.webConfig?.projectsId),
        limit(5)
      );
      const querySnapshot = await getDocs(next);
      querySnapshot.forEach((doc) => {
        arr.push({ id: doc.id, ...doc.data() });
      });
      setCourses(arr);
    } catch (error) {
      console.log(error.message, "error next");
    }

  };

  const handleLoadMore = async () => {
    setLoading(true);
    let arr = [];
    const lastVisible = courses[courses.length - 1]?.createdAt;
    try {
      const next = query(
        collection(db, "courses"),
        orderBy("createdAt"),
        where("projectsId", "==", value?.webConfig?.projectsId),
        startAfter(lastVisible),
        limit(5)
      );
      const querySnapshot = await getDocs(next);
      querySnapshot.forEach((doc) => {
        arr.push({ id: doc.id, ...doc.data() });
      });
      const updateData = [...courses, ...arr];
      setCourses(updateData);
      setLoading(false);
    } catch (error) {
      console.log(error.message, "error next");
    }
  };

  useEffect(() => {
    getCourses();
    getDataCategory();
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          console.log("reaching threshold... ");
          // handleLoadMore();
        }
      },
      { threshold: 1 }
    );

    if (observerTarget.current) {
      observer.observe(observerTarget.current);
    }

    return () => {
      if (observerTarget.current) {
        observer.unobserve(observerTarget.current);
      }
    };
  }, [observerTarget]);

  useEffect(() => {
    handleSeachCourses();
  }, [searchString]);

  return (
    <Box bg={bgCol}>
      <HStack alignItems={"center"} bgColor="brand.1" p="2">
        <BackButtons />
        <Heading textAlign="left" size="md" color={"black"}>
          Courses
        </Heading>
        <Spacer />
        <Stack alignItems="flex-end" justifyContent="center">
          <ViewBars setLayout={setLayout} layout={layout} />
        </Stack>
        <Stack alignItems="flex-end" justifyContent="center"></Stack>
      </HStack>
      <Flex my={2} w="full">
        <InputGroup mx={1}>
          <InputLeftElement
            pointerEvents="none"
            color="gray.300"
            fontSize="1.2em"
            children={<Icon as={AiOutlineSearch} />}
          />
          <Input
            placeholder="Search Courses / Videos"
            onChange={(e) => handleSearchCourse(e.target.value)}
          />
          <InputRightElement
            onClick={() => setSearchString("")}
            cursor="pointer"
            _hover={{
              backgroundColor: "gray.100",
            }}
          >
            <Icon
              as={MdClear}
              color="gray.400"
              _active={{
                transform: "scale(0.9)",
              }}
            />
          </InputRightElement>
        </InputGroup>
      </Flex>
      <Flex>
        <Flex gap={2}>
          {isFilteringCategory && (
            <Button onClick={removeFilterCategory} variant="outline">
              Reset
            </Button>
          )}
          {categoryList?.category?.map((x, index) => {
            return (
              <Tooltip label={`${x}`} key={index}>
                <Box
                  spacing={2}
                  borderBottomWidth={3}
                  borderColor={value?.webConfig?.brand[0]}
                  py={2}
                  px={2}
                  borderRadius="md"
                  shadow="md"
                >
                  <Text
                    mx="2"
                    maxW="150px"
                    textOverflow={"ellipsis"}
                    whiteSpace={"nowrap"}
                    overflow={"hidden"}
                    onClick={() => handleCategoryFilter(x)}
                    textTransform="capitalize"
                    fontWeight={
                      selectedCategoryNiche === x?.toLowerCase()
                        ? 500
                        : "normal"
                    }
                    color={
                      selectedCategoryNiche === x?.toLowerCase()
                        ? "blue.500"
                        : "gray.600"
                    }
                    cursor="pointer"
                  >
                    {x}
                  </Text>
                </Box>
              </Tooltip>
            );
          })}
        </Flex>
      </Flex>

      {courses?.length > 0 ? (
        <>
          <SimpleGrid
            columns={layout === "single" ? 1 : 2}
            my="2"
          >
            {isSearchActive &&
              searchResults?.map((x, i) => {
                return (
                  <Box key={i}>
                    <ListCardComponent
                      bgCol={bgCol}
                      fontCol={fontCol}
                      data={x}
                    />
                  </Box>
                );
              })}
            {!isSearchActive ? (
              courses?.map((x, i) => {
                return (
                  <Box key={i}>
                    <ListCardComponent
                      bgCol={bgCol}
                      fontCol={fontCol}
                      data={x}
                    />
                  </Box>
                );
              })
            ) : (
              <></>
            )}
          </SimpleGrid>
          {!isFilteringCategory ? (
            <Center>
              <Button
                colorScheme="blackAlpha"
                size="sm"
                onClick={handleLoadMore}
              >
                Load More Courses
              </Button>
            </Center>
          ) : (
            <></>
          )}
        </>
      ) : (
        <Box
          w="100%"
          bg={bgCol}
          h="70vh"
          display="flex"
          flexDir="column"
          justifyContent="center"
          alignItems="center"
        >
          <Text
            textAlign={"center"}
            fontWeight={"sm"}
            color={"gray.500"}
            mt="5"
          >
            No courses available
          </Text>
        </Box>
      )}
      <Box ref={observerTarget}></Box>
      {/* {loading && <Center>Loading....</Center>} */}
    </Box>
  );
}

export default CourseMicroSite;
