import React from 'react'
import { Route, Routes } from 'react-router-dom'
import MicroFullLayout from '../Layouts/MicroFullLayout'
import NewsMicroSite from '../Pages/News/NewsMicroSite'
import MicroHeaderLayout from '../Layouts/MicroHeaderLayout'
import NewsViewPage from '../Pages/Course/NewsViewPage'

function NewsRoutes() {
	return (
		<Routes>
			<Route path="/" element={<MicroFullLayout><NewsMicroSite /></MicroFullLayout>} />
			 <Route path="/:id" element={<MicroHeaderLayout><NewsViewPage /></MicroHeaderLayout>} />
			{/* <Route path="setting/*" element={<SettingRouter />} /> */}
			{/* <Route path="example/*" element={<ExampleRouter />} /> */}
		</Routes>
	)
}

export default NewsRoutes