import { Box, Button, Checkbox, Divider, Flex, FormControl, FormLabel, Heading, Input, Select } from '@chakra-ui/react'
import React from 'react'
import { FiAlertCircle } from 'react-icons/fi'

const Address = () => {
     return (
          <Box>
               <Heading p='2'>Billing Address</Heading>
               <Box p='2'>
                    <FormControl>
                         <FormLabel>Country</FormLabel>
                         <Select>
                              <option>Indonesia</option>
                         </Select>
                    </FormControl>
                    <FormControl>
                         <FormLabel>Stret Address</FormLabel>
                         <Input type='text' />
                    </FormControl>
                    <FormControl>
                         <FormLabel>Stret Address Line 2 (optional)</FormLabel>
                         <Input type='text' placeholder='Apt, floor, suite, bldg, etc' />
                    </FormControl>
                    <FormControl>
                         <FormLabel>City</FormLabel>
                         <Input type='text' />
                    </FormControl>
                    <FormControl>
                         <FormLabel>Postal Code</FormLabel>
                         <Input type='number' />
                    </FormControl>
                    <Flex align='center' gap='2'>
                         <Checkbox />Delivery address same as billing <FiAlertCircle />
                    </Flex>
               </Box>
               <Divider />
               <Box p='2'>

                    <Checkbox>Add a business tax ID</Checkbox>
                    <Flex gap='2' align='center'>
                         <Heading>Delivery address</Heading>
                         <FiAlertCircle />
                    </Flex>
                    <FormControl>
                         <FormLabel>Country</FormLabel>
                         <Select>
                              <option>Indonesia</option>
                         </Select>
                    </FormControl>
                    <FormControl>
                         <FormLabel>Street address</FormLabel>
                         <Input type='text' />
                    </FormControl>
                    <FormControl>
                         <FormLabel>Stret Address Line 2 (optional)</FormLabel>
                         <Input type='text' placeholder='Apt, floor, suite, bldg, etc' />
                    </FormControl>
                    <FormControl>
                         <FormLabel>City</FormLabel>
                         <Input type='text' />
                    </FormControl>
                    <FormControl>
                         <FormLabel>Postal Code</FormLabel>
                         <Input type='number' />
                    </FormControl>
                    <Button my='2'>Update Billing Info</Button>
               </Box>
          </Box>
     )
}

export default Address 