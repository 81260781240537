import * as React from "react";
import { useRef } from "react";
import { motion, useCycle } from "framer-motion";
import { useDimensions } from "./use-dimensions";
import { MenuToggle } from "./MenuToggle";
import { Navigation } from "./Navigation";
import { Center, } from "@chakra-ui/react";

// const sidebar = {
// 	open: (height = 1000) => ({
// 		clipPath: `circle(${height * 2 + 200}px at 40px 40px)`,
// 		transition: {
// 			type: "spring",
// 			stiffness: 20,
// 			restDelta: 2
// 		}
// 	}),
// 	closed: {
// 		clipPath: "circle(30px at 40px 40px)",
// 		transition: {
// 			delay: 0.5,
// 			type: "spring",
// 			stiffness: 400,
// 			damping: 40
// 		}
// 	}
// };

export const CircularMenuComponent = (props) => {
	const [isOpen, toggleOpen] = useCycle(false, true);
	const containerRef = useRef(null);
	const { height } = useDimensions(containerRef);

	return (
		<motion.nav
			initial={false}
			animate={isOpen ? "open" : "closed"}
			custom={height}
			ref={containerRef}
		>
			{/* <motion.div className="background" variants={sidebar} /> */}
			<Center
				mt='-5'
				justifyContent='center'
				alignItems='center'
				shadow='base' w='60px' h='60px'
				borderRadius='full'
				bgColor='brand.1'
				onClick={() => toggleOpen()}>
				<MenuToggle />
			</Center>
			<Center bgColor='blue'>
				<Navigation isOpen={isOpen} toggle={toggleOpen} data={props.data} />
			</Center>


		</motion.nav>
	);
};
