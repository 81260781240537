import React from 'react'
import { Route, Routes } from 'react-router-dom'
import MicroFullLayout from '../Layouts/MicroFullLayout'
import MicroHeaderLayout from '../Layouts/MicroHeaderLayout'
import ProductMicroSite from '../Pages/Products/ProductMicroSite'
import SingleProductMicroSite from '../Pages/Products/SingleProductMicroSite'

function TicketRoutes() {
     return (
          <Routes>
               <Route path="/" element={<MicroFullLayout><ProductMicroSite /></MicroFullLayout>} />
               <Route path="/:id" element={<MicroHeaderLayout><SingleProductMicroSite /></MicroHeaderLayout>} />
          </Routes>
     );
};

export default TicketRoutes