import {
  Box,
  Heading,
  Spacer,
  Text,
  Image,
  VStack,
  Badge,
  Flex,
  Skeleton,
  Stack,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { formatFrice } from "../../Utils/NumberUtils";

function ListProductCardComponent(props) {
  const x = props.data;
  const col = props.bgCol;
  const fontCol = props.fontCol;
  // const [isLoaded, setIsLoaded] = useState(false);

  // const handleLoaded = () => {
  // 	setIsLoaded(true)
  // 	// console.log("image loaded")
  // };

  return (
    <Link to={`${x?.id}`}>
      <VStack
        m="2"
        shadow="md"
        borderRadius="md"
        mx={2}
        my={2}
        bg={col}
        // maxH="350px"
      >
        {/* {!isLoaded ? <Skeleton w='full' h='200px' /> : */}
        <Image
          borderTopRadius={10}
          objectFit="contain"
          w="full"
          //   h="200px"
          // src='https://process.fs.teachablecdn.com/ADNupMnWyR7kCWRvm76Laz/resize=width:705/https://cdn.filestackcontent.com/bLy3JtIoQ8y8PDs4tFem'
          src={x?.thumbnail || x?.image}
          alt={x?.title}
        />
        {/* } */}
        <VStack>
          <Box m={1} minH="100px">
            <Flex
              gap={1}
              flexDir={"column"}
              justify="space-between"
              align="center"
            >
              {props?.data?.category ? (
                props?.data?.category?.map((item, i) => (
                  <Badge
                    fontSize={"xs"}
                    key={i}
                    mt="1"
                    colorScheme="blue"
                    h="fit-content"
                  >
                    {item}
                  </Badge>
                ))
              ) : (
                <></>
              )}
              <Heading fontSize="md">{x?.title}</Heading>
            </Flex>
            <Stack justify="center" align="center" my={1}>
              <Text fontSize="sm" color={fontCol} noOfLines={3}>
                {x?.description}
              </Text>
              <Text fontSize={"sm"} fontWeight={500}>
                Rp{formatFrice(parseInt(x?.price))}
              </Text>
            </Stack>
          </Box>
          <Spacer />
          {/* <Button justifySelf='flex-end' w='full' colorScheme={value.webConfig.colorScheme}>View</Button> */}
        </VStack>
      </VStack>
    </Link>
  );
}

export default ListProductCardComponent;
