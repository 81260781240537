import React from 'react'
import { Route, Routes } from 'react-router-dom'
import MicroFullLayout from '../Layouts/MicroFullLayout'
import MicroHeaderLayout from '../Layouts/MicroHeaderLayout'
import AccountMicro from '../Pages/Settings/AccountMicro'
import Profile from '../Pages/Settings/Profile'
import Subsriptions from '../Pages/Settings/Subsriptions'
import CreditCard from '../Pages/Settings/CreditCard'
import Address from '../Pages/Settings/Address'
import Contact from '../Pages/Settings/Contact'
import OrderHistory from '../Pages/Settings/OrderHistory'


function SettingRoutes() {
	return (
		<Routes>
			<Route path="/" element={<MicroFullLayout><AccountMicro /></MicroFullLayout>} />
			<Route path="/edit-profile" element={<MicroHeaderLayout>< Profile /></MicroHeaderLayout>} />
			<Route path="/subscriptions" element={<MicroHeaderLayout>< Subsriptions /></MicroHeaderLayout>} />
			<Route path="/credit-card" element={<MicroHeaderLayout><CreditCard /></MicroHeaderLayout>} />
			<Route path="/address" element={<MicroFullLayout><Address /></MicroFullLayout>} />
			<Route path="/contact" element={<MicroFullLayout><Contact /></MicroFullLayout>} />
			<Route path="/order-history" element={<MicroFullLayout><OrderHistory /></MicroFullLayout>} />

			{/* <Route path="setting/*" element={<SettingRouter />} /> */}
			{/* <Route path="example/*" element={<ExampleRouter />} /> */}
		</Routes>
	)
}

export default SettingRoutes