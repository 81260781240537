import { Text, Flex } from "@chakra-ui/react";
import React from "react";
import { FiArrowLeft } from "react-icons/fi";
import { useNavigate } from "react-router-dom";

function BackButtons() {
  const navigate = useNavigate();
  return (
    <Flex
      my={2}
      direction="row"
      alignItems="center"
      gap={2}
      onClick={() => navigate(-1)}
      sx={{ cursor: "pointer" }}
      color={"gray.800"}
    >
      <FiArrowLeft size={20} />
      {/* <Text fontWeight={500} fontSize={14}>
        Back
      </Text> */}
    </Flex>
  );
}

export default BackButtons;
