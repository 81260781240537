import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { RoutesAnimation } from './RoutesAnimation';
import PaymentPage from '../Pages/User/PaymentPage';
import Error404 from '../Pages/Error/Error404';
import ShipperForm from '../Components/Forms/ShipperForm';
import JneForm from '../Components/Forms/JneForm';



function UserRoutes() {
	return (
		<Routes>
			<Route element={<RoutesAnimation />}>
				<Route path="/payment" element={<Error404 />} />
				<Route path="/payment/:id" element={<PaymentPage />} />
				<Route path="/forms/shipper" element={<ShipperForm />} />
				<Route path="/forms/jne" element={<JneForm />} />
			</Route>
		</Routes>
	)
}

export default UserRoutes