import React from 'react'
import { Route, Routes } from 'react-router-dom'
import MicroFullLayout from '../Layouts/MicroFullLayout'
import SosmedlinkPage from '../Pages/Sosmedlink/SosmedlinkPage'

function SosmedlinkRoutes() {
	return (
		<Routes>
			<Route path="/" element={<MicroFullLayout><SosmedlinkPage /></MicroFullLayout>} />
		</Routes>
	)
}

export default SosmedlinkRoutes