import React from 'react'
import { Route, Routes } from 'react-router-dom'
import MicroFullLayout from '../Layouts/MicroFullLayout'
import TicketMicroSite from '../Pages/Ticket/TicketMicroSite'
import SingleTicketMicroSite from '../Pages/Ticket/SingleTicketMicroSiteV2'
import MicroHeaderLayout from '../Layouts/MicroHeaderLayout'
import FormTicketMicroSite from '../Pages/Ticket/FormTicketMicroSite'
import RegistrationForms from '../Components/Forms/RegistrationForm'

function TicketRoutes() {
     return (
          <Routes>
               <Route path="/" element={<MicroFullLayout><TicketMicroSite /></MicroFullLayout>} />
               <Route path="/:id" element={<MicroHeaderLayout><SingleTicketMicroSite /></MicroHeaderLayout>} />
               <Route path="/:id/:ticketId/form" element={<MicroHeaderLayout><FormTicketMicroSite /></MicroHeaderLayout>} />
               <Route path="/registration" element={<MicroHeaderLayout><RegistrationForms /></MicroHeaderLayout>} />
          </Routes>
     )
}

export default TicketRoutes