const Typesense = require("typesense");

// tyeosense apikey is harcoded because code doesn't read env file, not solved..
const API = "Bnc8j41P6HyAySid8vc218BusSAXh30f";

export let clientTypesense = new Typesense.Client({
  nodes: [
    {
      host: "bynu8vp41sx3m0zwp-1.a1.typesense.net", // For Typesense Cloud use xxx.a1.typesense.net
      port: "443", // For Typesense Cloud use 443
      protocol: "https", // For Typesense Cloud use https
    },
  ],
  apiKey: API,
  connectionTimeoutSeconds: 2,
});