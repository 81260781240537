import { AspectRatio, Box, Text } from '@chakra-ui/react'
import React from 'react'
import { FaLock } from 'react-icons/fa'
import ReactPlayer from 'react-player'

const ExpiredCourseComponent = (props) => {
    const { courseDetail } = props;

    return (
        <Box>
            <Box >
                <AspectRatio ratio={16 / 9} position={'relative'}>
                    <ReactPlayer
                        width='full'
                        height='full'
                        controls={true}
                        url={courseDetail?.media}
                        pip
                        config={{
                            youtube: {
                                playerVars: { showinfo: 1 }
                            },
                        }}
                    />
                </AspectRatio>
                <Box alignItems={'center'} display='flex' flexDirection={'column'} justifyContent={'center'} w={'full'} aspectRatio={16 / 9} bgColor='blackAlpha.800' position={'absolute'} top={0}>
                    <FaLock color='white' size={40} />
                    <Text color={'white'} fontWeight={500} fontSize='sm' >This course has expired. Please repurchase.</Text>
                </Box>
            </Box>

        </Box>
    )
}

export default ExpiredCourseComponent