import { Box, Flex, Heading, Text } from '@chakra-ui/react';
import React, { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import parse from 'html-react-parser';
import { getCollectionWhereFirebase, getSingleDocumentFirebase } from '../../Api/firebaseApi';
import { FiArrowLeft, FiChevronLeft, FiChevronRight } from 'react-icons/fi';
import { GlobalContext } from '../../Hooks/Contexts/GlobalContext';


const PromptResultFilePage = () => {
    const params = useParams()
    const navigate = useNavigate()
    const [filePrompt, setFilePrompt] = useState([])
    const [nextPage, setNextPage] = useState(null)
    const [prevPage, setPrevPage] = useState(null)
    const [currentLesson, setCurrentLesson] = useState({ idCourse: null, idLesson: 0 })
    const [currentPrevLesson, setCurrentPrevLesson] = useState({ idCourse: null, idLesson: 0 })
    const [allPrompt, setAllPrompt] = useState([]);

    const getDataFilePrompt = async () => {
        const res = await getSingleDocumentFirebase('files', params?.id_file)
        if (res) {
            setFilePrompt(res)
            const res2 = await getCollectionWhereFirebase('files', 'folderId', '==', res?.folderId)
            if (res2) {
                setAllPrompt(res2)
            }
        }

    }

    const getNextLesson = async () => {
        if (allPrompt?.length > 0) {
            const courses = allPrompt
            const findCourse = await courses?.findIndex(e => e.id === params?.id_file)
            const course = await allPrompt[findCourse + 1]
            setNextPage(course)
            setCurrentLesson({ idCourse: findCourse, idLesson: findCourse + 1 })

        }
    };

    const getBackLesson = async () => {
        if (allPrompt?.length > 0) {
            const courses = allPrompt
            const findCourse = await courses?.findIndex(e => e.id === params?.id_file)
            const course = await allPrompt[findCourse - 1]
            setCurrentPrevLesson({ idCourse: findCourse, idLesson: findCourse - 1 })
            setPrevPage(course)
        }
    };


    const handleNext = () => {
        navigate(`/course/${params.courseId}/prompt-gpt/${nextPage.id}`)
    };

    const handleBack = () => {
        navigate(`/course/${params.courseId}/prompt-gpt/${prevPage.id}`)
    };

    useEffect(() => {
        getDataFilePrompt()
        getNextLesson()
        getBackLesson()
    }, [allPrompt?.length !== 0])


    return (
        <>
            <Flex onClick={() => navigate(`/course/${params.courseId}`)} px='2' py='2' fontSize='25px' cursor={'pointer'}>
                <FiArrowLeft />
            </Flex>
            <Box minH='100vh' px={5}>
                {filePrompt && filePrompt?.content ?
                    <Box >

                        <Heading textAlign={'center'} size={'md'}>{filePrompt?.heading}</Heading>
                        <Text p={5}>{parse(filePrompt?.content)}</Text>

                    </Box>
                    : <></>
                }

            </Box>
            {(allPrompt?.length > 1 && currentLesson?.idLesson !== 1 && currentLesson?.idLesson < allPrompt?.length) ?
                <Flex pos='sticky' bottom='0' bgColor={'white'}>
                    <Flex onClick={() => handleBack()} p='2' shadow={'base'} px='2' fontSize='25px' align='center' gap='2' w='100%' cursor={'pointer'} >
                        <FiChevronLeft /><Heading size='sm'>Previous Lesson</Heading>
                    </Flex>
                    <Flex onClick={() => handleNext()} p='2' shadow={'base'} px='2' fontSize='25px' justifyContent={'right'} align={'center'} gap='2' w='100%' cursor={'pointer'} >

                        <Heading size='sm'>Next Lesson</Heading><FiChevronRight />
                    </Flex>
                </Flex> :
                currentLesson?.idLesson === 1 ?
                    <Flex onClick={() => handleNext()} p='2' shadow={'base'} px='2' fontSize='25px' justifyContent={'right'} align={'center'} gap='2' w='100%' cursor={'pointer'} bgColor={'white'}>

                        <Heading size='sm'>Next Lesson</Heading><FiChevronRight />
                    </Flex>
                    :
                    currentPrevLesson?.idLesson < allPrompt?.length ?
                        <Flex onClick={() => handleBack()} p='2' shadow={'base'} px='2' fontSize='25px' align='center' gap='2' w='100%' cursor={'pointer'} bgColor={'white'} >
                            <FiChevronLeft /><Heading size='sm'>Previous Lesson</Heading>
                        </Flex>
                        :

                        <></>}
        </>

    )
}

export default PromptResultFilePage