import React, { useContext, useEffect } from "react";
import { ChakraProvider, extendTheme, ScaleFade, Stack, Text } from "@chakra-ui/react";
import MainRoutes from "./Routes/MainRoutes";
import LoaderComponent from "./Components/Spinners/LoaderComponent";
import { GlobalContext } from "./Hooks/Contexts/GlobalContext";
import Error404 from "./Pages/Error/Error404";
import { inject } from "@vercel/analytics";
import { useLocation } from "react-router-dom";
import UserRoutes from "./Routes/UserRoutes";

// Menambahkan inisialisasi untuk alat analitik Vercel
inject();

function App() {
  const value = useContext(GlobalContext);

  useEffect(() => {
    // Mengatur judul dokumen berdasarkan konfigurasi web
    if (value?.webConfig?.webName === undefined) {
      document.title = "DeoApp";
    } else {
      document.title = value?.webConfig?.webName;
    }

    // Memperbarui favicon dengan nilai dari konfigurasi web
    const faviconLink = document.querySelector("link[rel='icon']");
    faviconLink.href = value?.webConfig?.favicon;

    // Return kosong jika tidak ada dependensi perubahan
    return () => {};
  }, [value?.webConfig?.projectsId]);

  return (
    <ChakraProvider
      theme={extendTheme({
        colors: { brand: value?.webConfig?.brand ? value.webConfig.brand : 0 },
      })}
    >
      {value.loading ? (
        <ScaleFade initialScale={36} in>
          <Stack alignItems="center" minH="98vh" justifyContent="center">
            <LoaderComponent />
            <Text>Build & design by deoapp com</Text>
          </Stack>
        </ScaleFade>
      ) : value?.webConfig?.brand ? (
        <MainRoutes />
      ) : (
        <UserRoutes />
      )}
    </ChakraProvider>
  );
}

export default App;
