import { Alert, Box, Button, Flex, FormControl, FormHelperText, FormLabel, Heading, Image, Input, SimpleGrid, Spacer } from '@chakra-ui/react'
import React, { useContext, useEffect, useState } from 'react'
import { GlobalContext } from '../../Hooks/Contexts/GlobalContext';

const CreditCard = () => {
     const value = useContext(GlobalContext);

     const [data, setData] = useState({ name: null, expired: null, cvv: null, cardNumber: null })

     const formatCardNumber = (type) => {
          let string;
          let formattedValue = "";
          if (type === 'card') {
               var cardNumber = document.getElementById("cardNumber");
               string = cardNumber.value.replace(/\D/g, '');

               for (var i = 0; i < string.length; i++) {
                    if (i % 4 === 0 && i > 0) {
                         formattedValue += " ";
                    }
                    formattedValue += string[i];
               }
               cardNumber.value = formattedValue;

          } else if (type === 'expired') {
               var expired = document.getElementById("expired");
               string = expired.value.replace(/\D/g, '');

               for (var i = 0; i < string.length; i++) {
                    if (i % 2 === 0 && i > 0) {
                         formattedValue += "/";
                    }
                    formattedValue += string[i];
               }
               expired.value = formattedValue;

          } else {
               var cardNumber = document.getElementById("cvv");
               string = cardNumber.value.replace(/\D/g, '');
               cardNumber.value = string;
          }
     }

     useEffect(() => {
          return () => {
               setData();
          };
     }, [])

     return (
          <Box>
               <SimpleGrid columns='1' bgColor='brand.1' p='2'>
                    <Heading size="md" color={'black'}>Add / Change Credit Card</Heading>
               </SimpleGrid>

               <Alert status='success' variant='subtle'>
                    You must keep a card on file as long as you have an active subscription or payment plan.
               </Alert>
               <Box px='2' mt='5'>
                    <FormControl isRequired >
                         <FormLabel>Name on Card</FormLabel>
                         <Input type='text' placeholder='Input your name' onChange={(e) => setData({ ...data, name: e.target.value })} />
                    </FormControl>
                    <Box my='5'>
                         <FormControl isRequired isInvalid={data?.cardNumber?.length !== 19}>
                              <FormLabel>Card Number</FormLabel>
                              <Flex justify='space-between' align='center'>
                                   <Input type='tel' placeholder='1234 1234 1234 1234' maxLength='19' w='40%' variant='unstyled' name="cardNumber" id="cardNumber" onInput={() => formatCardNumber('card')} onChange={(e) => setData({ ...data, cardNumber: e.target.value })} />
                                   <Spacer />
                                   <Image w='10%' src='https://encrypted-tbn3.gstatic.com/images?q=tbn:ANd9GcR_FrTaaaGEk9eULQpb355SxtAFizG5jleBqp_1q8j2dgMxqfHT' mr='2' />
                                   <Image w='10%' src='https://upload.wikimedia.org/wikipedia/commons/thumb/b/b7/MasterCard_Logo.svg/2560px-MasterCard_Logo.svg.png' />
                              </Flex>
                              {data?.cardNumber && data?.cardNumber?.length !== 19 ? (
                                   <FormHelperText color='red'>
                                        Card number should be 16 digits
                                   </FormHelperText>
                              ) : <></>}
                         </FormControl>
                         {/* <FormControl>
                              <FormLabel>Country</FormLabel>
                              <Select>
                                   <option>Select your billing country</option>
                              </Select>
                         </FormControl> */}
                    </Box>
                    <Flex w='100%'>
                         <FormControl isRequired isInvalid={data?.expired?.length !== 5}>
                              <FormLabel>Expiration Date</FormLabel>
                              <Input type='text' placeholder='MM/YY' maxLength='5' variant='unstyled' name="expired" id="expired" onInput={() => formatCardNumber('expired')} onChange={(e) => setData({ ...data, expired: e.target.value })} />
                              {data?.expired && data?.expired?.length !== 5 ? (
                                   <FormHelperText color='red'>
                                        Expiration date should be 4 digits
                                   </FormHelperText>
                              ) : <></>}
                         </FormControl>
                         <FormControl isRequired isInvalid={data?.cvv?.length !== 3}>
                              <FormLabel>CVV Code</FormLabel>
                              <Input type='text' placeholder='CVV' maxLength='3' variant='unstyled' name="cvv" id="cvv" onInput={() => formatCardNumber('cvv')} onChange={(e) => setData({ ...data, cvv: e.target.value })} />
                              {data?.cvv && data?.cvv?.length !== 3 ? (
                                   <FormHelperText color='red'>
                                        CVV code should be 3 digits
                                   </FormHelperText>
                              ) : <></>}
                         </FormControl>
                    </Flex>
                    {/* <FormControl>
                         <FormLabel>Postal Code</FormLabel>
                         <Input type='number' placeholder='90210' variant='unstyled' />
                    </FormControl> */}
                    <Box my='10'>
                         <Button w='full' colorScheme={value?.webConfig?.colorScheme}>{data?.name && data?.cardNumber && data?.cvv && data?.expired ? 'Add Credit Card' : 'Add Credit Card'}</Button>
                    </Box>

               </Box>
          </Box>
     )
}

export default CreditCard