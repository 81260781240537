import {
  Box,
  Button,
  Center,
  Container,
  Divider,
  Flex,
  Heading,
  HStack,
  Image,
  SimpleGrid,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";
import React from "react";
import { IoLogoWhatsapp } from "react-icons/io5";
import { CarouselComponent } from "../../Components/Galleries/Carousel/CarouselComponent";
import { useState } from "react";
import { getSingleDocumentFirebase } from "../../Api/firebaseApi";
import { Link, useParams } from "react-router-dom";
import { useEffect } from "react";
import { PriceTag } from "../../Components/Carts/CartDrawer/PriceTag";
import { FcBusinessContact } from "react-icons/fc";
import { useContext } from "react";
import { GlobalContext } from "../../Hooks/Contexts/GlobalContext";
import BackButtons from "../../Components/Buttons/BackButtonComponent";

function ListingViewMicroSite() {
  const value = useContext(GlobalContext);
  const params = useParams();
  const [data, setData] = useState(null);

  const getData = async () => {
    const res = await getSingleDocumentFirebase("listings", params.id);
    let images = [];
    images.push(res.image);
    const newData = {
      ...res,
      images: images,
    };
    console.log(newData);

    setData(newData);
  };

  const feature = [
    { title: "13 Cabang", subtitle: "di seluruh indonesia" },
    { title: "Biaya Marketing", subtitle: "3% dari omzet" },
    { title: "ROI/BEP", subtitle: "12 bulan" },
  ];

  useEffect(() => {
    getData();

    return () => {
      setData();
    };
  }, []);

  return (
    <>
      <BackButtons />

      <Box pos="relative">
        {<CarouselComponent images={data?.images} aspectRatio={16 / 9} />}
        <Box m="2" p="2" w="100%">
          <Text>Start From</Text>
          <HStack w="100%">
            <Heading fontSize="lg">{<PriceTag price={data?.price} />}</Heading>
            {data?.priceEnd && (
              <Flex align="center">
                <Heading fontSize="lg" mr="2">
                  -
                </Heading>
                <Heading fontSize="lg">
                  {<PriceTag price={data?.priceEnd} />}
                </Heading>
              </Flex>
            )}
          </HStack>
        </Box>

        <Divider />
        <Stack py="5" px="2">
          <Flex justify={"space-between"}>
            <Heading>{data?.title}</Heading>
            {data?.logo && (
              <Image
                src={data?.logo}
                aspectRatio={16 / 9}
                w="100px"
                h="50px"
                objectFit={"contain"}
              />
            )}
          </Flex>
          <Text>{data?.description}</Text>
        </Stack>

        <Divider />

        <SimpleGrid columns={2} m="2" my="4">
          {data?.details?.map((x, i) => (
            <Flex boxShadow={"sm"} borderRadius="md" m="1" p="1" key={i}>
              <Text pr="1" mt="2">
                <FcBusinessContact fontSize={"27px"} />
              </Text>
              <VStack spacing={2} justify={"left"} align={"left"} p="1.5">
                <Text textTransform={"capitalize"} fontWeight={"medium"}>
                  {x.key}
                </Text>
                {x?.value?.includes("https://") ? (
                  <Link to={`${x.value}`} target="_blank">
                    <Text color="blue">Click here</Text>
                  </Link>
                ) : (
                  <Text overflowWrap={"break-word"} w={"148px"}>
                    {x.value}
                  </Text>
                )}
              </VStack>
            </Flex>
          ))}
        </SimpleGrid>
        <Box position="sticky" bottom="0" width="full" p="2">
          <Link
            to={`https://api.whatsapp.com/send?phone=${data?.contactPerson}&text=Halo, saya mendapatkan info *${data?.title}* dari website *${data?.projectName}*. Mohon informasi lebih lanjut untuk join kemitraan. Terima kasih.

 :)`}
            target="_blank"
          >
            <Button
              colorScheme={value?.webConfig?.colorScheme}
              w="full"
              leftIcon={<IoLogoWhatsapp />}
            >
              {" "}
              Contact seller
            </Button>
          </Link>
        </Box>
      </Box>
    </>
  );
}

export default ListingViewMicroSite;
