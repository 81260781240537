import { Box, Heading, Spacer, Text, Image, VStack, Badge, Flex, Skeleton, Stack } from '@chakra-ui/react'
import React, { useState } from 'react'
import { Link } from 'react-router-dom';

function ListCardComponent(props) {
	const x = props.data;
	const col = props.bgCol
	const fontCol = props.fontCol


	return (
		<Link to={`${x?.id}`}>
			<Stack spacing={3} shadow='md' borderRadius='md' m={2} p={2} bg={col} maxH='350px'>
				<Image borderTopRadius={10} objectFit="contain" w='full' h='200px'
					src={x?.thumbnail || x?.image}
					alt={x?.title}
				/>

				<Stack mx={2} minH='100px'>
					<Stack >
						{props?.data?.tags ? props?.data?.tags?.map((item, i) => (
							<Badge key={i} mt='1' colorScheme='blue' h='fit-content'>{item}</Badge>
						)) : <></>}
						<Text fontSize='sm' fontWeight={"bold"} textTransform='capitalize'>{x?.title}</Text>


					</Stack>
					<Flex >
						<Text fontSize='xs' color={fontCol} noOfLines={3}>{x?.description}</Text>
					</Flex>

				</Stack>
			</Stack>

		</Link>

	)
}

export default ListCardComponent