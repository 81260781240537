import { AspectRatio, Box, Flex, Heading, HStack, Spacer } from '@chakra-ui/react';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ReactPlayer from 'react-player';
import { getCollectionWhereFirebase, getSingleDocumentFirebase } from '../../Api/firebaseApi';
import parse from 'html-react-parser';
import { GlobalContext } from '../../Hooks/Contexts/GlobalContext';
import { FiArrowLeft, FiArrowRight } from "react-icons/fi";

function SingleLessonSite(props) {
	const params = useParams();
	const navigate = useNavigate()
	const value = useContext(GlobalContext)
	const [dataLesson, setDataLesson] = useState({});
	const [nextPage, setNextPage] = useState(null)
	const [prevPage, setPrevPage] = useState(null)
	const [currentLesson, setCurrentLesson] = useState({ idCourse: null, idLesson: 0 })
	const [currentPrevLesson, setCurrentPrevLesson] = useState({ idCourse: null, idLesson: 0 })

	const getLessonDetail = async () => {
		const res = await getSingleDocumentFirebase(`courses/${params.courseId}/lessons`, params.lessonId);
		setDataLesson(res);
	};

	const getNextLesson = async () => {
		const courses = value?.courses
		const findCourse = await courses?.findIndex(e => e.id === params.courseId)

		const course = await value?.courses[findCourse]?.lessons
		const findLesson = await course?.findIndex(e => e.id === params.lessonId)

		const number = parseInt(findLesson) + 1
		const nextArray = await value?.courses[findCourse]?.lessons[number]

		setCurrentLesson({ idCourse: findCourse, idLesson: findLesson + 1 })
		setNextPage(nextArray)
	};

	const getBackLesson = async () => {
		const courses = value?.courses
		const findCourse = await courses?.findIndex(e => e.id === params.courseId)
		const course = await value?.courses[findCourse]?.lessons
		const findLesson = await course?.findIndex(e => e.id === params.lessonId)
		const number = parseInt(findLesson) - 1
		const nextArray = await value?.courses[findCourse]?.lessons[number]


		setCurrentPrevLesson({ idCourse: findCourse, idLesson: findLesson - 1 })


		setPrevPage(nextArray)
	};

	const handleNext = () => {
		navigate(`/course/${params.courseId}/lesson/${nextPage?.id}`)
	};

	const handleBack = () => {
		navigate(`/course/${params.courseId}/lesson/${prevPage?.id}`)
	};


	useEffect(() => {
		getLessonDetail()
		getNextLesson()
		getBackLesson()
		return () => {
			setDataLesson({})
			setCurrentLesson({ idCourse: null, idLesson: 0 })
			setCurrentPrevLesson({ idCourse: null, idLesson: 0 })
			setNextPage(null)
			setPrevPage(null)
		}

	}, [value])

	if (dataLesson)
		return (
			<>
				{/* <Flex onClick={() => navigate(`/course/${params.courseId}`)} px='2' py='2' fontSize='25px' cursor={'pointer'}>
					<FiArrowLeft />
				</Flex> */}
				<Box minH='100%' pos={'relative'}>


					<Box mb='5' shadow='base' >
						{dataLesson?.media && (

							dataLesson.sourceType === "file" ? (
								<AspectRatio ratio={16 / 9} alignItems='center' justifyContent={'center'}>
									<iframe src={dataLesson?.media || 'https://www.youtube.com/watch?v=ysz5S6PUM-U'} title="File Preview" width="auto" height="auto"></iframe>

								</AspectRatio>
							) : (
								<AspectRatio ratio={16 / 9} alignItems='center' justifyContent={'center'}>
									<ReactPlayer
										width='full'
										height='full'
										controls
										playing
										url={dataLesson?.media ? dataLesson.media : 'https://www.youtube.com/watch?v=ysz5S6PUM-U'}
									/>
								</AspectRatio>
							)

						)}

					</Box>

					{(value?.courses[currentLesson?.idCourse]?.lessons?.length > 1 && currentLesson?.idLesson !== 1 && currentLesson?.idLesson < value?.courses[currentLesson?.idCourse]?.lessons?.length) ?
						<Flex pos='sticky' bottom='0'>
							<Flex onClick={() => handleBack()} p='2' shadow={'base'} px='2' fontSize='25px' align='center' gap='2' w='100%' cursor={'pointer'} >
								<FiArrowLeft /><Heading size='sm'>Previous Lesson</Heading>
							</Flex>
							<Flex onClick={() => handleNext()} p='2' shadow={'base'} px='2' fontSize='25px' justifyContent={'right'} align={'center'} gap='2' w='100%' cursor={'pointer'} >

								<Heading size='sm'>Next Lesson</Heading><FiArrowRight />
							</Flex>
						</Flex> :
						currentLesson?.idLesson === 1 ?
							<Flex onClick={() => handleNext()} p='2' shadow={'base'} px='2' fontSize='25px' justifyContent={'right'} align={'center'} gap='2' w='100%' cursor={'pointer'} >

								<Heading size='sm'>Next Lesson</Heading><FiArrowRight />
							</Flex>
							:
							currentPrevLesson?.idLesson < value?.courses[currentPrevLesson?.idCourse]?.lessons?.length ?
								<Flex onClick={() => handleBack()} p='2' shadow={'base'} px='2' fontSize='25px' align='center' gap='2' w='100%' cursor={'pointer'} >
									<FiArrowLeft /><Heading size='sm'>Previous Lesson</Heading>
								</Flex>
								:

								<></>}

					<Box mb='5' shadow='base' >
						<HStack px='5' py='5'>
							<Heading size='md'>{dataLesson?.title}</Heading>
							<Spacer />
						</HStack>
					</Box>

					{dataLesson && dataLesson?.description ?
						<Box mb='5' shadow='base' px='5' pb='5' fontSize={'md'}>
							{parse(dataLesson?.description)}
						</Box>
						:
						<></>
					}

					{/* <HStack pos='sticky' bottom='5' width='full'> */}

					{/* </HStack> */}
				</Box>
			</>
		)
}

export default SingleLessonSite